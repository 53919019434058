<template>
  <div>
    <article class="card mb-2 pb-8 pt-7 px-4 px-md-7 px-lg-10">
      <div class="w-100 text-center mb-4">
        <img alt="Ongoing Maintenance" src="@/assets/icons/monitoring-load-data.svg" />
      </div>
      <p v-dynamic-content:maintenance_message_text></p>
    </article>
  </div>
</template>

<script>
export default {
  name: 'MaintenanceMode',
}
</script>
