<template>
  <b-container class="p-0">
    <b-row>
      <b-col cols="12">
        <b-alert
          :variant="display"
          :show="true"
          dismissible
          @dismissed="resetBanner"
          :class="'referrals-submission-' + display"
        >
          <div v-if="display === 'danger'">
            <div v-dynamic-content:referrals_submit_failure></div>
          </div>
          <div v-if="display === 'success'" class="referrals-submission-success">
            <div v-dynamic-content:referrals_submit_success></div>
          </div>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: ['display', 'resetBanner'],
}
</script>
