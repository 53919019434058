const AWS = require('aws-sdk')
const cognito = new AWS.CognitoIdentityServiceProvider({ region: 'eu-central-1' })
import { Auth } from 'aws-amplify'

function transformArrayToObject(array) {
  return array.reduce((object, attribute) => {
    object[attribute.Name] = attribute.Value
    return object
  }, {})
}

export default {
  state: {
    adminUser: '',
    impersonatedAccount: {},
    userList: [],
  },
  getters: {
    isAdmin: (state) => (state.adminUser ? true : false),
  },
  mutations: {
    SET_ADMIN_USER(state, adminUser) {
      state.adminUser = adminUser
    },
    SET_USER_LIST(state, userList) {
      state.userList = userList
    },
    SET_IMPERSONATED_ACCOUNT(state, impersonatedAccount) {
      state.impersonatedAccount = impersonatedAccount
    },
    SET_ADMIN_LANGUAGE(state, locale) {
      state.impersonatedAccount.locale = locale
    },
  },
  actions: {
    setAdminUser(context, adminUser) {
      context.commit('SET_ADMIN_USER', adminUser)
    },
    setImpersonatedAccount(context, impersonatedAccount) {
      context.commit(
        'SET_IMPERSONATED_ACCOUNT',
        transformArrayToObject(impersonatedAccount.Attributes)
      )
    },
    adminSignOut(context) {
      Auth.signOut()
      context.commit('SET_ADMIN_USER', '')
    },
    async listUsers(context, params) {
      const credentials = await Auth.currentCredentials()
      cognito.config.credentials = credentials
      const userList = await cognito.listUsers(params).promise()
      if (!userList || !userList.Users || !userList.Users.length) {
        context.commit('SET_USER_LIST', [])
        return
      }
      context.commit('SET_USER_LIST', userList.Users)
    },
    setAdminLanguage(context, locale) {
      context.commit('SET_ADMIN_LANGUAGE', locale)
    },
  },
}
