<template>
  <div>
    <div class="faq-page container card-full-width-mobile mt-md-9 px-md-0 pt-md-0 pb-md-9">
      <article class="card mb-2 pt-7 px-4 px-md-7 px-lg-10">
        <h2
          class="card-title text-primary font-weight-bold mt-4 mt-lg-7 mb-4 mb-md-6 mb-lg-7"
          v-dynamic-content:monitoring_faqpage_title
        ></h2>
        <p class="mb-7" v-dynamic-content:monitoring_faqpage_text></p>
        <div v-if="faqs">
          <div v-for="(faq, key) of faqs" :key="key" class="mb-7">
            <section>
              <h3 class="text-primary mb-7">{{ key }}</h3>
              <div class=" ">
                <div v-for="(item, index) of faq" :key="index" class="question mb-3">
                  <div
                    class="collapse-item d-flex justify-items-between align-items-center"
                    v-b-toggle="item.question"
                  >
                    <h4
                      class="pl-3 mb-0 mt-1"
                      v-html="$options.filters.replaceDynamicContent(item.question)"
                    ></h4>
                  </div>
                  <b-collapse :id="item.question" class="pl-6 mt-2" accordion="faq">
                    <p v-html="$options.filters.replaceDynamicContent(item.answer)"></p>
                  </b-collapse>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div class="d-flex justify-content-between flex-wrap align-items-end">
          <backto-top-button></backto-top-button>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import BacktoTopButton from '@/app/components/main/scroll/BacktoTopButton'

export default {
  name: 'FaqPage',
  mixins: [],
  components: {
    BacktoTopButton,
  },
  computed: {
    content() {
      return this.$store.state.data.content
    },
    profile() {
      return this.$store.state.data.profile
    },
    faqs() {
      if (!this.$store.getters.faqs) return []
      const items = this.$store.getters.faqs('faqpage-faqs')
      return items
    },
  },
  mounted() {
    this.$store.commit('SET_HEADER_TEXT', null)
  },
  beforeUpdate() {
    this.$store.commit('SET_HEADER_TEXT', null)
  },
}
</script>
