// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'es6-promise/auto'
import 'whatwg-fetch'

// types must be imported first!
import '@/app/types'

import Vue from 'vue'
import { router } from '@/app/router'
import store from '@/app/store'
import loader from '@/app/components/main/loader/Loader'
import formTemplate from '@/app/components/main/form/FormTemplate'
import '@/app/filters'
import '@/app/mixins'
import '@/app/components/authorization'
import '@/app/components/customer/monitoring'
import '@/app/components/customer/referrals'
import '@/app/components/b2b'
import '@/scss/sungevity/sungevity.scss'
import config from 'config'
import BootstrapVue from 'bootstrap-vue'
import smoothscroll from 'smoothscroll-polyfill'
import VueMoment from 'vue-moment'
import Fragment from 'vue-fragment'
import VueRouter from 'vue-router'
import Vuelidate from 'vuelidate'
import VueMq from 'vue-mq'
import App from './App'
import queryString from 'query-string'
import axios from 'axios'
import Amplify, * as AmplifyModules from 'aws-amplify'
import { AmplifyPlugin } from 'aws-amplify-vue'
import dynamicContent from '@/app/directives/dynamic-content'
import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { InMemoryCache } from 'apollo-cache-inmemory'
import VueApollo from 'vue-apollo'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  Vue,
  dsn: 'https://0d441051428744aba451faff9f0b7cef@o557955.ingest.sentry.io/5703416',
  integrations: [new Integrations.BrowserTracing()],
  environment: window.location.host,
  // Set tracesSampleRate to .1 in prod to capture 10%
  // of transactions for performance monitoring in production.
  // Sampling enables representative data without overwhelming either
  // your system or your Sentry transaction quota.
  tracesSampleRate: window.location.host.indexOf('my.sungevity') > -1 ? 0.1 : 1,
  logErrors: true, //call original Vue's logError function as well.
})

configureAmplify(window.location.pathname)
smoothscroll.polyfill()

if (!config) throw 'Config is missing'

// save the base url to the whole app
store.commit('SET_BASE_URL', config.endpoints)
store.commit('SET_EXTERNAL_QUERY', queryString.parse(window.location.search))
store.commit('SET_ADMIN_PORTAL_URL', config['/sg/admin-portal/url'])

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(VueMoment)
Vue.use(Fragment.Plugin)
Vue.directive('dynamic-content', dynamicContent)
Vue.use(VueMq, {
  breakpoints: {
    xs: 0,
    sm: 320,
    md: 768,
    lg: 1024,
    xl: Infinity,
  },
})
Vue.config.productionTip = false
Vue.use(AmplifyPlugin, AmplifyModules)

const country = getCountryCode()
store.commit('SET_COUNTRY_CODE', country)
const locale = getLocale(country)
store.commit('SET_LOCALE', locale)

// push locale to dataLayer GTM
window.dataLayer = window.dataLayer || []
window.dataLayer.push({
  pageLanguage: locale,
})

Vue.use(VueRouter)

Vue.component('loader', loader)
Vue.component('form-template', formTemplate)

/*
  APOLLO SETUP
*/

// HTTP connection to the API
const customFetch = async (uri, options) => {
  const {
    idToken: { jwtToken },
  } = await AmplifyModules.Auth.currentSession()
  options.headers.Authorization = jwtToken
  return fetch(uri, options)
}
const httpLink = createHttpLink({
  uri: config.endpoints['monitoring-platform-api'],
  fetch: customFetch,
})
const cache = new InMemoryCache({
  cacheRedirects: {
    Query: {
      getLocation(_, args, { getCacheKey }) {
        return getCacheKey({
          __typename: 'Location',
          id: args.id,
        })
      },
    },
  },
})
const apolloClient = new ApolloClient({
  link: httpLink,
  cache,
})
window.client = apolloClient // for debugging
window.gql = require('graphql-tag')

Vue.use(VueApollo)

const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
})

new Vue({
  render: (h) => h(App),
  validations: {},
  router,
  store,
  apolloProvider,
}).$mount('#app')

Vue.$http = axios.create({
  baseURL: config.endpoints.api,
})

function getCountryCode() {
  const hostMatch = /sungevity.(nl|be|it)/g.exec(window.location.hostname)
  const country = hostMatch ? hostMatch[1] : 'be'
  return country
}

function getLocale(country) {
  const availableLocales = config['available.locales'][country]

  // set locale logic, first check browser language
  let browserLocale = navigator.language || navigator.browserLanguage
  const regex = /-/gi
  browserLocale = browserLocale.replace(regex, '_')

  // get external locale from context
  const externalQuery = queryString.parse(window.location.search)

  // First check if externalLocal exists
  if (externalQuery && externalQuery.locale) {
    if (
      externalQuery.locale.indexOf('_') > -1 &&
      availableLocales.join().toLowerCase().indexOf(externalQuery.locale.toLowerCase()) > -1
    ) {
      return externalQuery.locale
    }
  }
  // when browser locale matches available locales.. use it
  if (
    browserLocale.indexOf('_') > -1 &&
    availableLocales.join().toLowerCase().indexOf(browserLocale.toLowerCase()) > -1
  ) {
    const [lang, country] = browserLocale.split('_')
    return `${lang.toLowerCase()}_${country.toUpperCase()}`
  }
  // else, use fallback locale from config global
  return availableLocales[0]
}

//TODO: Cognito should probably be reworked in the future to keep
// admin users and customers users in the same Forward user pool
// See https://sungevity-int.atlassian.net/browse/SG-1215 for more info
export function configureAmplify(pathname) {
  const isAdmin = pathname === '/admin'
  const isLocalDev = window.location.host.indexOf('localhost') > -1

  if (isAdmin) {
    Amplify.configure({
      Auth: {
        oauth: {
          domain: config.amplifyConfigAdmin.domain,
          scope: ['openid', 'email', 'aws.cognito.signin.user.admin'],
          redirectSignIn: isLocalDev
            ? 'http://localhost:8080/admin'
            : config.amplifyConfigAdmin.redirectSignIn,
          redirectSignOut: isLocalDev
            ? 'http://localhost:8080/admin'
            : config.amplifyConfigAdmin.redirectSignOut,
          responseType: 'code',
        },
        identityPoolId: config.amplifyConfigAdmin.identityPoolId,
        region: 'eu-central-1',
        userPoolId: config.amplifyConfigAdmin.userPoolId,
        userPoolWebClientId: config.amplifyConfigAdmin.userPoolWebClientId,
      },
    })
  } else {
    Amplify.configure({
      Auth: {
        region: 'eu-central-1',
        userPoolId: config['/global/cognito.user.pool.id'],
        userPoolWebClientId: config['/global/cognito.user.pool.app.client.id'],
        identityPoolId: config['/global/cognito.identity.pool.id'],
      },
      Storage: {
        AWSS3: {
          bucket: config['/sg/aws-s3-bucket/name/my-sg-image-bucket'],
          region: 'eu-central-1',
        },
      },
    })
  }
}
