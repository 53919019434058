<template>
  <div>
    <div class="container card-full-width-mobile mt-3 mt-md-9 p-0">
      <article class="card mb-2 pb-8 pt-7 px-4 px-md-7 px-lg-10">
        <div v-if="isAdmin">
          <h3>Currently viewing</h3>
          <p>{{ profile.email }}</p>
          <p>Account number {{ profile.accountNumber }}</p>
          <b-button variant="primary" @click="clearImpersonatedUser()">View another user</b-button>
        </div>
        <energy-production></energy-production>
        <environmental-gains v-if="location" class="mt-7"></environmental-gains>
      </article>
    </div>
    <div class="container">
      <div class="container-fluid">
        <div
          class="content-low row align-items-start justify-content-between mt-8 mt-md-10 pb-md-7"
        >
          <FAQ
            :pagelink="{ text: content.monitoring_faq_subtext_link, link: '/monitoring/faq' }"
            page="monitoring-faqs"
          />
          <ContentCard
            v-if="referralsEnabled"
            :title="'monitoring_sharesun_title'"
            :text="'monitoring_sharesun_text'"
            :link="'monitoring_sharesun_link'"
            :imgSrc="'icons/share-the-sun.svg'"
            :linkTo="'ReferralPage'"
            :circleImage="'true'"
            class="mt-7 mt-md-0 mb-sm-3"
          />
          <BacktoTopButton></BacktoTopButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FAQ from '@/app/components/main/faq/FAQ'
import ContentCard from '@/app/components/main/content/ContentCard'
import BacktoTopButton from '@/app/components/main/scroll/BacktoTopButton'
import EnergyProduction from '../Energyproduction'
import EnvironmentalGains from '../EnvironmentalGains'
import { mapGetters } from 'vuex'

export default {
  name: 'Overview',
  mixins: [],
  components: {
    FAQ,
    EnergyProduction,
    ContentCard,
    BacktoTopButton,
    EnvironmentalGains,
  },
  methods: {
    setHeaderText() {
      const headerText = this.$options.filters.replaceDynamicContent(
        this.content.monitoring_header_welcome,
        [this.profile.firstName]
      )
      this.$store.commit('SET_HEADER_TEXT', headerText)
    },
    viewNewUser() {
      this.$router.push('/admin')
    },
    clearImpersonatedUser() {
      this.$store.dispatch('clearLocation')
      this.$router.push('/admin')
    },
  },
  computed: {
    ...mapGetters(['isAdmin', 'location', 'referralsEnabled']),
  },
  created() {
    if (!this.location.id) {
      this.$store.dispatch('getLocation')
    }
  },
}
</script>
