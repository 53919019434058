<template>
  <div class="container card-full-width-mobile">
    <article class="card mb-2 py-8 px-6 px-lg-10">
      <section class="card-body pt-4">
        <div class="w-100 text-center mb-4">
          <img
            style="width: 73px; height: 64px"
            alt="Password sent"
            src="@/assets/icons/passwordrequestsent.svg"
          />
        </div>
        <h2
          class="card-title text-primary font-weight-bold"
          v-dynamic-content:login_title_passwordrequested
        ></h2>
        <p v-dynamic-content:login_text_passwordrequested></p>
      </section>
    </article>
  </div>
</template>

<script>
export default {
  name: 'ForgotPassword',
  beforeUpdate() {
    this.$store.commit('SET_HEADER_TEXT', null)
  },
}
</script>
