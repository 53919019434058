<template>
  <header>
    <HeaderNavbar />
    <HeaderBanner />
    <LoginHeader />
  </header>
</template>

<script>
import HeaderNavbar from './HeaderNavbar'
import HeaderBanner from './HeaderBanner'
import LoginHeader from './LoginHeader'

export default {
  name: 'Header',
  components: {
    HeaderNavbar,
    HeaderBanner,
    LoginHeader,
  },
}
</script>
