<template>
  <div>
    <h2
      class="card-title text-primary font-weight-bold monitoring-overview-title-text mt-0 mt-md-4 mt-lg-7 mb-4 mb-md-6 mb-lg-7"
      v-dynamic-content:monitoring_production_title
    ></h2>
    <p v-dynamic-content:monitoring_production_intro></p>
    <div :key="a.text" v-for="a in alerts">
      <alert-banner v-bind="a"></alert-banner>
    </div>

    <div
      class="card px-md-5 py-md-5"
      v-if="(installationFound && firstReadingAt) || !initialized"
      :class="{ loading: !initialized }"
    >
      <div class="monitoring-production" id="app">
        <b-row class="mt-1 mt-lg-6 monitoring-graph-header-controls">
          <select-chart-options></select-chart-options>
          <select-categories></select-categories>
        </b-row>
        <b2c-chart></b2c-chart>
        <select-interval order-md="2" order-sm="1"></select-interval>
      </div>
    </div>
    <div v-else>
      <loading-data></loading-data>
    </div>
  </div>
</template>

<script>
import AlertBanner from '@/app/components/main/banner/AlertBanner.vue'
import LoadingData from './LoadingData'
import B2cChart from './B2cChart'
import SelectInterval from './SelectInterval'
import SelectChartOptions from './SelectChartOptions'
import SelectCategories from './SelectCategories'
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'EnergyProduction',
  components: {
    AlertBanner,
    LoadingData,
    SelectChartOptions,
    SelectCategories,
    SelectInterval,
    B2cChart,
  },
  computed: {
    ...mapState({
      firstReadingAt: ({ monitoring }) => monitoring.location.firstReadingAt,
      initialized: ({ monitoring }) => monitoring.initialized,
      loading: ({ monitoring }) => monitoring.loading.getDeltas,
      selectedUtility: ({ monitoring }) => monitoring.selectedUtility,
    }),
    ...mapGetters([
      'location',
      'installationFound',
      'deltaQueryInput',
      'locale',
      'showSolarmanPre2021Warning',
      'showProductionSpikeWarning',
      'noDataForDateRange',
      'showNegativeProductionWarning',
      'initialReadingKwhProduced',
      'brands',
    ]),
    alerts() {
      //don't show errors before we fetched the location
      if (!this.initialized) return []
      return [
        {
          show: this.brands.includes('Omnik'),
          display: 'alert',
          dismissable: true,
          text: this.content.monitoring_omnik_data_breach_model,
        },
        {
          show: !this.installationFound,
          display: 'heavy-alert',
          dismissible: false,
          text: this.content.monitoring_no_installations_error_modal,
        },
        {
          show: this.installationFound && this.errorMessage,
          display: 'alert',
          dismissible: false,
          text: this.errorMessage,
        },
        {
          show: this.initialReadingKwhProduced,
          display: 'alert',
          dismissible: false,
          text: `${this.content.monitoring_production_graph_hide_initial_reading_warning}: ${this.initialReadingKwhProduced}`,
        },
      ].filter((a) => a.show)
    },
    errorMessage() {
      if (this.noDataForDateRange && !this.loading)
        return this.content.monitoring_no_data_for_date_range_error_message
      if (this.showSolarmanPre2021Warning)
        return this.content.monitoring_production_graph_solarman_pre_2021_warning
      if (this.showNegativeProductionWarning)
        return this.content.monitoring_production_graph_negative_reading_warning
      if (this.showProductionSpikeWarning)
        return this.content.monitoring_production_graph_first_reading_warning
      return ''
    },
  },
  watch: {
    deltaQueryInput: {
      immediate: true,
      handler(args) {
        args.locationId && this.$store.dispatch('wrapLoading', { action: 'getDeltas' })
      },
    },
    locale: {
      immediate: true,
      handler(v) {
        const price = {
          fr_BE: 0.28,
          nl_BE: 0.28,
          nl_NL: 0.23,
        }[v]
        this.$store.commit('SET_INPUT_FIELD', { key: 'tariff', value: price })
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.3;
}
</style>
