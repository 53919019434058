<template>
  <div v-if="visible" class="container pb-2 header-intro login-header">
    <b-row>
      <b-col>
        <template>
          <img
            :src="require(`../../../../assets/icons/mysungevity/logo-sungevity-engie-white.svg`)"
          />
        </template>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'HeaderBanner',
  computed: {
    visible() {
      // only show on login page
      const isLogin = this.$route.path.indexOf(Vue.types.pages.LOGIN) > -1 ? true : false
      return isLogin
    },
  },
}
</script>
