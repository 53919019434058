import {
  required as requiredValidation,
  email as emailValidation,
  sameAs as sameAsValidation,
} from 'vuelidate/lib/validators'

// custom validation for password strength.
const passwordStrength = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})')
  if (regex.test(value)) {
    return true
  }
  return false
}

// custom validation for verification code.
const codemismatchValidation = (value) => {
  if (typeof value === 'undefined' || value === null || value === '') {
    return true
  }
  if (value.length === 6 && /\d{6}/.test(value)) {
    return true
  }
  return false
}

// default export mixin base layer for validation which can be used as mixin.
export default {
  data() {
    return {
      loading: null,
    }
  },
  computed: {
    errorMsg() {
      return this.$store.getters.formErrorMessage()
    },
  },
  mounted() {
    this.$store.dispatch('setFormError', null)
  },
  validations: {
    email: {
      requiredValidation,
      emailValidation,
    },
    password: {
      requiredValidation,
    },
    newPassword: {
      requiredValidation,
      passwordStrength,
    },
    newPassword2: {
      requiredValidation,
      sameAsValidation: sameAsValidation('newPassword'),
    },
    verificationCode: {
      requiredValidation,
      codemismatchValidation,
    },
    agree: {
      sameAsValidation: sameAsValidation(() => true),
    },
  },
  methods: {
    formErrorHandling(error) {
      this.loading = false
      this.$store.dispatch('setFormError', error)
    },
  },
}
