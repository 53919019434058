<template>
  <form-template>
    <template slot="side">
      <inlog-locale-switch></inlog-locale-switch>
      <h2 class="text-primary" v-dynamic-content:create_new_password_title></h2>
      <password-rules></password-rules>
    </template>

    <template slot="form">
      <b-alert variant="danger" :show="!!errorMsg">{{ errorMsg }}</b-alert>
      <form @submit.prevent="changePassword" novalidate>
        <div>
          <div class="pb-6">
            <p v-dynamic-content:login_confirm_password_intro_text></p>
            <label class="mb-0" v-dynamic-content:login_confirm_password_email_label></label>
            <b-form-input type="email" :value="email" disabled></b-form-input>
          </div>

          <div class="pb-6">
            <p v-dynamic-content:login_change_password_intro_text></p>
            <label class="mb-0" v-dynamic-content:login_field_password_new_text></label>
            <b-form-input
              type="password"
              v-model="newPassword"
              :placeholder="content.login_field_password_new"
              :state="$v.newPassword.$dirty ? !$v.newPassword.$invalid : null"
              :disabled="loading"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div v-if="!$v.newPassword.requiredValidation">
                {{ content.login_error_required }}
              </div>
              <div
                v-if="
                  $v.newPassword.requiredValidation && !$v.newPassword.passwordStrengthValidation
                "
              >
                {{ content.login_error_password_invalid }}
              </div>
            </b-form-invalid-feedback>
          </div>
          <div class="pb-6">
            <label class="mb-0" v-dynamic-content:login_field_password_new_confirm_text></label>
            <b-form-input
              type="password"
              v-model="newPassword2"
              :placeholder="content.login_field_password_new_confirm"
              :state="$v.newPassword2.$dirty ? !$v.newPassword2.$invalid : null"
              :disabled="loading"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div v-if="!$v.newPassword2.requiredValidation">
                {{ content.login_error_required }}
              </div>
              <div v-if="$v.newPassword2.requiredValidation && !$v.newPassword2.sameAsValidation">
                {{ content.login_error_passwordnomatch }}
              </div>
            </b-form-invalid-feedback>
          </div>
          <b-button class="btn-block" type="submit" variant="primary" :disabled="loading">
            <span
              class="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
              v-if="loading"
            ></span>
            <span v-dynamic-content:login_button_login></span>
          </b-button>
        </div>
        <router-link to="/login/forgotpassword">
          <p class="text-center mt-4">{{ content.login_forget }}</p>
        </router-link>
      </form>
    </template>
  </form-template>
</template>

<script>
import { Auth } from 'aws-amplify'

import FormMixin from '@/app/components/main/form/FormMixin'
import InlogLocaleSwitch from './parts/InlogLocaleSwitch'
import PasswordRules from './parts/PasswordRules'

export default {
  name: 'ChangePassword',
  prop: ['value'],
  components: {
    InlogLocaleSwitch,
    PasswordRules,
  },
  mixins: [FormMixin],
  data() {
    return {
      email: this.$route.params.email,
      password: this.$route.params.password,
      newPassword: null,
      newPassword2: null,
      loading: false,
    }
  },
  methods: {
    async changePassword() {
      const email = this.email.toLowerCase()
      const password = this.password
      const newPassword = this.newPassword

      this.$v.$touch()
      if (this.$v.newPassword.$invalid || this.$v.newPassword2.$invalid) {
        return
      }

      try {
        this.loading = true
        const user = await Auth.signIn({ username: email, password })
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          await Auth.completeNewPassword(user, newPassword)
          this.$store.commit('SET_EXTERNAL_QUERY', null)
          this.$router.push('/')
        } else {
          this.$router.push('/login')
        }
      } catch (error) {
        this.loading = false
        this.formErrorHandling(error)
      }
    },
  },
  beforeUpdate() {
    this.$store.commit('SET_HEADER_TEXT', null)
  },
}
</script>
