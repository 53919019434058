<template>
  <div class="container mt-8 pb-8">
    <div class="mb-4">
      <router-link
        :to="{ name: 'B2BLocationList', query: { page } }"
        v-dynamic-content:b2b_location_details_back_to_overview_link
      ></router-link>
    </div>

    <div class="card px-4 px-md-8 px-lg-10 pt-8">
      <div v-if="!$apollo.queries.getLocation.loading && !getLocation">
        This location does not exist
      </div>
      <div v-else>
        <b-spinner v-if="$apollo.queries.getLocation.loading"></b-spinner>

        <h2 class="pb-4">{{ getLocation.address || '...' }}</h2>

        <div class="d-flex mb-2">
          <div class="col-4 pl-0" v-dynamic-content:b2b_locations_table_column_city></div>
          <div>{{ getLocation.city }}</div>
        </div>
        <div class="d-flex mb-6">
          <div class="col-4 pl-0" v-dynamic-content:b2b_locations_table_column_complex_number></div>
          <div>{{ getLocation.customerReference }}</div>
        </div>

        <div class="d-flex mb-6">
          <div class="col-4 pl-0" v-dynamic-content:b2b_locations_table_column_status></div>
          <div>
            <div
              v-if="!getLocation.meteringSystems || getLocation.meteringSystems.length === 0"
              class="d-flex"
            >
              <div class="inactive-icon"></div>
              <div>geen meter geïnstalleerd</div>
            </div>
            <metering-system-status
              v-else
              v-for="(ms, index) in getLocation.meteringSystems"
              :meteringSystem="ms"
              :key="index"
            ></metering-system-status>
          </div>
        </div>

        <div class="d-flex mb-6">
          <div
            class="col-4 pl-0"
            v-dynamic-content:b2b_locations_table_column_installed_by_sungevity
          ></div>
          <div>
            <div
              v-if="
                !getLocation.meteringSystems ||
                getLocation.meteringSystems.length === 0 ||
                !getLocation.meteringSystems[0].installedBySungevity
              "
            >
              &#10060;
            </div>
            <div v-else>&#x2705;</div>
          </div>
        </div>

        <div class="d-flex mb-2">
          <div class="col-4 pl-0" v-dynamic-content:b2b_locations_table_column_online_since></div>
          <div>{{ formatOnlineSince(getLocation.onlineSince) }}</div>
        </div>
        <div class="d-flex mb-2" v-if="getLocation.solarModule">
          <div
            class="col-4 pl-0"
            v-dynamic-content:b2b_locations_table_column_panel_description
          ></div>
          <div>
            {{ getLocation.solarModule.numberOfModules }}x
            {{ getLocation.solarModule.nameOfModule }}
          </div>
        </div>

        <h4 class="mt-10" v-dynamic-content:b2b_location_details_production_header></h4>

        <div class="my-6">
          <span v-dynamic-content:b2b_location_details_lifetime_production></span>
          {{ lifetimeProductionFormatted }}
        </div>

        <div class="mt-3 mb-8">
          <div class="graph-container p-4 p-md-6 p-lg-7">
            <b-spinner v-show="$apollo.queries.getMonthlyDeltas.loading"></b-spinner>

            <div :class="{ loading: $apollo.queries.getMonthlyDeltas.loading }">
              <location-deltas-graph-header
                :production="
                  getMonthlyDeltas.totalProductionDelta &&
                  getMonthlyDeltas.totalProductionDelta.kwhProduced
                "
                :guarantee="getMonthlyDeltas.totalProductionGuarantee"
              ></location-deltas-graph-header>
              <location-deltas-graph :deltas="getMonthlyDeltas.deltas"></location-deltas-graph>
              <location-deltas-progress
                :progress="progress"
                :currentYear="fetchedYear"
              ></location-deltas-progress>

              <div class="d-flex mt-3 flex-row-reverse">
                <div class="col-auto">
                  <year-selector
                    :onlineSince="getLocation.onlineSince"
                    v-model="selectedYear"
                  ></year-selector>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { DateTime } from 'luxon'
import MeteringSystemStatus from './MeteringSystemStatus'
import LocationDeltasGraph from '@/app/components/b2b/common/LocationDeltasGraph'
import LocationDeltasProgress from '@/app/components/b2b/common/LocationDeltasProgress'
import LocationDeltasGraphHeader from '@/app/components/b2b/common/LocationDeltasGraphHeader'
import YearSelector from '@/app/components/b2b/common/YearSelector'

export default {
  components: {
    MeteringSystemStatus,
    LocationDeltasGraph,
    LocationDeltasProgress,
    LocationDeltasGraphHeader,
    YearSelector,
  },
  data() {
    return {
      getLocation: '',
      getMonthlyDeltas: '',
      selectedYear: DateTime.local().year,
    }
  },
  methods: {
    formatOnlineSince(date) {
      if (!date) return
      return DateTime.fromISO(date).toFormat('dd-MM-y')
    },
  },
  computed: {
    page() {
      // this is the page number from the list view, to be used for the back button
      return this.$route.query.page
    },
    progress() {
      if (this.$apollo.queries.getMonthlyDeltas.loading) return '0%'
      const { totalProductionGuarantee, totalProductionDelta } = this.getMonthlyDeltas

      const production = Number(totalProductionDelta && totalProductionDelta.kwhProduced)
      const guarantee = Number(totalProductionGuarantee)

      const ratio = guarantee > 0 ? production / guarantee : 0
      return `${Math.round(ratio * 100)}%`
    },
    lifetimeProductionFormatted() {
      if (!this.getLocation.productionDelta) return '...'
      const formatted = Intl.NumberFormat('nl-NL', { maximumFractionDigits: 0 }).format(
        this.getLocation.productionDelta.kwhProduced
      )

      return `${formatted} kWh`
    },
    fetchedYear() {
      if (!this.getMonthlyDeltas) return this.selectedYear
      const date = DateTime.fromISO(this.getMonthlyDeltas.deltas[0].intervalStartAt)
      return date.year
    },
  },
  apollo: {
    getMonthlyDeltas: {
      query: gql`
        query getMonthlyDeltas($filter: MonthlyDeltasFilter!) {
          getMonthlyDeltas(filter: $filter) {
            totalProductionGuarantee
            totalProductionDelta {
              kwhProduced
            }
            deltas {
              intervalStartAt
              productionDelta {
                kwhProduced
              }
              productionGuarantee
            }
          }
        }
      `,
      variables() {
        const currentYear = DateTime.fromObject({ year: this.selectedYear })
        return {
          filter: {
            locationId: this.$route.params.id,
            intervalStartAt: currentYear.toISODate(),
            intervalEndAt: currentYear.endOf('year').toISODate(),
          },
        }
      },
    },
    getLocation: {
      query: gql`
        query getLocation($id: Int!) {
          getLocation(id: $id) {
            id
            address
            city
            onlineSince
            customerReference
            solarModule {
              numberOfModules
              nameOfModule
            }
            productionDelta {
              kwhProduced
            }
            meteringSystems {
              installedBySungevity
              status
              inactive {
                type
              }
            }
          }
        }
      `,
      variables() {
        return {
          id: this.$route.params.id,
        }
      },
      // we have some but not all data from the location list
      // with this setting, we can immediatly show what we have while we fetch the rest
      returnPartialData: true,
    },
  },
}
</script>

<style scoped lang="scss">
.back-icon {
  background-image: url('../../../../assets/icons/back-arrow.svg');
  width: 0.5em;
}

h2,
h4 {
  color: $brand-color;
}
img {
  width: 1.2em;
}
a,
a:hover,
a:active {
  text-decoration: none;
  color: white;
  background-image: url('../../../../assets/icons/back-arrow.svg');
  background-repeat: no-repeat;
  background-position: left center;
  padding-left: 2em;
}
.card {
  padding: 24px;
}
.spinner-border {
  color: $brand-color;
  position: absolute;
  top: 3em;
  left: 3em;
}
.col-4 {
  font-weight: 800;
}
.error {
  color: red;
}
.graph-container {
  border: 1px solid rgb(221, 225, 233);
  border-radius: 5px;
  position: relative;
}
.graph-container .spinner-border {
  position: absolute;
  top: 50%;
  left: 50%;
}
.loading {
  opacity: 0.3;
}

.inactive-icon {
  width: 0.5em;
  height: 0.5em;
  margin: 0.5em 0.5em 0.5em 0;
  border-radius: 0.5em;
  background-color: #fde68a;
}
</style>
