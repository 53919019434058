<template>
  <section class="section" v-if="kwhProduced > 0">
    <div class="d-flex justify-items-between align-items-center mb-5 mt-4">
      <h2 class="text-primary m-0" v-dynamic-content:monitoring_environmental_gains_title></h2>
    </div>
    <div class="content">
      <p
        class="mr-md-10"
        v-html="
          $options.filters.replaceDynamicContent(content.monitoring_environmental_gains_text, [
            kwhProduced.toLocaleString(),
          ])
        "
      ></p>
      <div class="row justify-content-center mt-md-7 pt-md-4">
        <img
          class="col px-0 d-none d-md-block mb-5"
          src="@/assets/icons/milieubijdrage-line-art.svg"
        />
      </div>
      <div class="row">
        <div class="col-12 col-md-4 text-center mb-4">
          <img
            class="d-inline-block d-md-none mb-3"
            style="width: 135px"
            src="@/assets/icons/house-illustration.svg"
          />
          <div class="environment-discription w-100 font-weight-bold text-primary">
            {{ co2KgSaved }}
            <a class="info-symbol ml-1" role="button" data-info-popup="co2CalculationPopup"></a>
          </div>
          <h4
            class="font-size-bold px-9 px-md-0 mb-3"
            v-html="
              $options.filters.replaceDynamicContent(
                content.monitoring_environmental_gains_reducedco2
              )
            "
          ></h4>
        </div>
        <div class="col-12 col-md-4 text-center mb-4">
          <img
            class="d-inline-block d-md-none mb-3"
            style="width: 135px"
            src="@/assets/icons/car-illustration.svg"
          />
          <div class="environment-discription w-100 font-weight-bold text-primary">
            {{ carKmSaved }}
            <a class="info-symbol ml-1" role="button" data-info-popup="kmsCalculationPopup"></a>
          </div>
          <h4
            class="font-size-bold px-9 px-md-0 mb-3"
            v-dynamic-content:monitoring_environmental_gains_reducedkm
          ></h4>
        </div>
        <div class="col-12 col-md-4 text-center mb-4">
          <img
            class="d-inline-block d-md-none mb-3"
            style="width: 135px"
            src="@/assets/icons/trees-illustration.svg"
          />
          <div class="environment-discription w-100 font-weight-bold text-primary">
            {{ treesSaved }}
            <a class="info-symbol ml-1" role="button" data-info-popup="treesCalculationPopup"></a>
          </div>
          <h4
            class="font-size-bold px-9 px-md-0 mb-3"
            v-dynamic-content:monitoring_environmental_gains_trees
          ></h4>
        </div>
      </div>
      <div class="mt-md-7" v-if="content.monitoring_environmental_gains_shop_url">
        <p class="mr-md-10" v-dynamic-content:monitoring_environmental_gains_sales_pitch></p>
        <p class="mr-md-10" v-dynamic-content:monitoring_environmental_gains_shop_explanation></p>
        <a
          :href="content.monitoring_environmental_gains_shop_url"
          target="_blank"
          class="font-weight-bold"
        >
          <span>
            {{ content.monitoring_environmental_gains_shop_link_text }}
            <img class="ml-2" src="@/assets/icons/forward.svg" />
          </span>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
function formatValue(value, unit, locale) {
  return new Intl.NumberFormat(locale, { style: 'unit', unit }).format(value)
}

export default {
  name: 'EnvironmentalGains',
  computed: {
    kwhProduced() {
      const { location } = this.$store.getters
      return location.productionDelta && location.productionDelta.kwhProduced
    },
    environmentalImpact() {
      const { location } = this.$store.getters
      return location.productionDelta ? location.productionDelta.environmentalImpact : null
    },
    co2KgSaved() {
      return formatValue(this.environmentalImpact.co2KgSaved, 'kilogram', this.language)
    },
    carKmSaved() {
      return formatValue(this.environmentalImpact.carKmSaved, 'kilometer', this.language)
    },
    treesSaved() {
      return this.environmentalImpact.treesSaved
    },
    language() {
      return this.$store.getters.locale.slice(0, 2)
    },
  },
}
</script>

<style lang="scss" scoped>
.info-symbol {
  content: '';
  display: inline-block;
  background: url('../../../../assets/icons/info-symbol.svg') no-repeat;
  width: 18px;
  height: 18px;
  margin: 0;

  &:hover {
    background: url('../../../../assets/icons/info-symbol-hover.svg') no-repeat;
    background-size: 18px;
  }
}
</style>
