<template>
  <div>
    <div v-if="$mq === 'xl'">
      <template v-if="isB2B">
        <b-dropdown-header class="d-flex justify-content-center">
          <strong class="pr-3">{{ companyName }}</strong>
        </b-dropdown-header>
        <b-dropdown-divider></b-dropdown-divider>
      </template>
      <b-dropdown-header class="d-flex justify-content-center">
        <span class="pr-3 font-weight-normal">{{ profile.firstName }} {{ profile.lastName }}</span>
      </b-dropdown-header>
      <b-dropdown-divider></b-dropdown-divider>
    </div>
    <template v-if="availableLocales.length > 1">
      <b-dropdown-header class="d-flex justify-content-center">
        <strong class="pr-3">{{ content.dropdown_languague }}</strong>
        <locale-switch></locale-switch>
      </b-dropdown-header>
      <b-dropdown-divider></b-dropdown-divider>
    </template>
    <b-dropdown-header class="d-flex justify-content-center">
      <logout></logout>
    </b-dropdown-header>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LocaleSwitch from './LocaleSwitch'
import Logout from './Logout'

export default {
  name: 'ProfileMenuContent',
  mixins: [],
  components: {
    LocaleSwitch,
    Logout,
  },
  computed: {
    ...mapGetters(['availableLocales', 'profile', 'isB2B', 'companyName']),
  },
}
</script>
