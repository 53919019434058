import { router, routerGuards } from '@/app/router'
import B2BDashboard from '@/app/components/b2b/dashboard/B2BDashboard'
import B2BLocationList from '@/app/components/b2b/locations/B2BLocationList'
import B2BLocationDetails from '@/app/components/b2b/locations/B2BLocationDetails'
import B2BProductionOverview from '@/app/components/b2b/production/B2BProductionOverview'

router.addRoute({
  path: '/b2b',
  name: 'B2BDashboard',
  component: B2BDashboard,
  beforeEnter: routerGuards.ifAuthenticated,
})
router.addRoute({
  path: '/b2b/locations',
  name: 'B2BLocationList',
  component: B2BLocationList,
  beforeEnter: routerGuards.ifAuthenticated,
})
router.addRoute({
  path: '/b2b/locations/:id',
  name: 'B2BLocationDetails',
  component: B2BLocationDetails,
  beforeEnter: routerGuards.ifAuthenticated,
})
router.addRoute({
  path: '/b2b/production',
  name: 'B2BProductionOverview',
  component: B2BProductionOverview,
  beforeEnter: routerGuards.ifAuthenticated,
})
