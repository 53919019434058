import { Auth } from 'aws-amplify'
import config from 'config'

export default {
  state: {
    authenticatedUserData: null,
    baseUrl: '',
    content: '',
    entryUrl: '',
    formError: '',
    faqs: '',
    headertext: '',
    locale: '',
    theme: '',
    faqPage: '',
    externalQuery: '',
    scrollPosition: '',
    countryCode: null,
    adminPortalLink: '',
    screenWidth: window.innerWidth,
    referralsEnabled:
      config['/sg/my-sungevity/referrals-enabled'] &&
      config['/sg/my-sungevity/referrals-enabled'] > 0,
  },
  getters: {
    referralsEnabled: (state) => state.referralsEnabled,
    isMobile: ({ screenWidth }) => screenWidth < 768,
    isB2B: (_, getters) =>
      getters.profile && getters.profile.groups.some((name) => name.startsWith('b2b')),
    companyCognitoGroupName: (_, getters) =>
      getters.profile.groups.find((name) => name.startsWith('b2b')),
    baseUrl: (state) => state.baseUrl,
    content: (state) => state.content,
    faqs: (state) => (page) => {
      if (!state.faqs) return
      const items = Object.values(state.faqs.faq)
      const categories = items.map((i) => i.category).filter((v, i, a) => a.indexOf(v) === i)

      return categories.reduce((obj, curr) => {
        const filtered = items.filter((i) => i.category === curr && i.pages.includes(page))
        if (filtered.length) obj[curr] = filtered
        return obj
      }, {})
    },
    flatFaqs: (state) => (page) => {
      if (!state.faqs) return
      const items = Object.values(state.faqs.faq)
      return items.filter((i) => i.pages && i.pages.includes(page))
    },
    headertext: (state) => state.headertext,
    language: (_, getters) => getters.locale.substring(0, 2),
    locale: (state, getters) => (getters.profile ? getters.profile.locale : state.locale),
    faqPage: (state) => state.faqPage,
    externalQuery: (state) => state.externalQuery,
    availableLocales: (state) => {
      if (!state.countryCode) return undefined
      return config['available.locales'][state.countryCode]
    },
    profile({ authenticatedUserData }, _b, rootState) {
      const { admin } = rootState
      let attributes
      if (admin.adminUser && admin.impersonatedAccount)
        attributes = admin.impersonatedAccount || undefined
      else {
        if (!authenticatedUserData) return undefined
        attributes = authenticatedUserData
      }
      return {
        locale: attributes.locale,
        email: attributes.email,
        accountNumber: attributes['custom:accountNumber'],
        firstName: attributes.given_name,
        lastName: attributes.family_name,
        groups: attributes['cognito:groups'] || [],
        allAtt: attributes,
      }
    },
    formErrorMessage: (state) => () => {
      // login_error_invalid_email: 'Dit is geen geldig e-mail adres.'
      // login_error_required: 'Dit veld is verplicht'
      // login_error_invalid_codemismatch: 'De verificatie code is niet geldig'

      // login_error_emailaddressunknown: "login_error_emailaddressunknown"
      // login_error_password_invalid: "Je wachtwoord is niet sterk genoeg (gebruik minimaal 8 tekens en minstens één nummer of speciaal teken)."
      // login_error_password_reset_required: "login_error_password_reset_required"
      // login_error_passwordnomatch: "Je wachtwoord komt niet overeen."
      // login_error_required_fields: "login_error_required_fields"
      // login_error_unauthorized: "We herkennen je e-mailadres of je wachtwoord niet. Let op hoofdletters, spaties en leestekens. "
      // login_error_unknown: "Er is een fout opgetreden. Probeer het later opnieuw."
      // login_error_user_not_confirmed: "login_error_user_not_confirmed"
      // login_error_user_not_found: "We herkennen je e-mailadres of je wachtwoord niet. Let op hoofdletters, spaties en leestekens. "
      // this.errorMsg = error.message;
      const error = state.formError
      if (error) {
        if (error.code === 'UserNotConfirmedException') {
          return state.content.login_error_user_not_confirmed || error.message
        }
        if (error.code === 'PasswordResetRequiredException') {
          return state.content.login_error_password_reset_required || error.message
        }
        if (error.code === 'NotAuthorizedException') {
          return state.content.login_error_unauthorized || error.message
        }
        if (error.code === 'UserNotFoundException') {
          return state.content.login_error_user_not_found || error.message
        }
        if (error.code === 'CodeMismatchException') {
          return state.content.login_error_codemismatch || error.message
        }
        return state.content.login_error_unknown
      }
      return null
    },
    fullName: (state, getters) => `${getters.profile.firstName} ${getters.profile.lastName}`,
  },
  mutations: {
    SET_WIDTH(state, width) {
      state.screenWidth = width
    },
    OVERWRITE_AUTHENTICATED_USER_DATA_LOCALE(state, locale) {
      state.authenticatedUserData.locale = locale
    },
    SET_AUTHENTICATED_USER_DATA(state, userData) {
      state.authenticatedUserData = userData
    },
    SET_BASE_URL(state, baseUrl) {
      state.baseUrl = baseUrl
    },
    SET_COUNTRY_CODE(state, countryCode) {
      state.countryCode = countryCode
    },
    SET_LOCALE(state, locale) {
      state.locale = locale
    },
    SET_EXTERNAL_QUERY(state, externalQuery) {
      state.externalQuery = externalQuery
    },
    SET_CONTENT(state, content) {
      state.content = content
    },
    SET_FAQS(state, faqs) {
      state.faqs = faqs
    },
    SET_HEADER_TEXT(state, text) {
      state.headertext = text
    },
    SET_ENTRY_URL(state, url) {
      state.entryUrl = url
    },
    SET_FORM_ERROR(state, error) {
      state.formError = error
    },
    SET_ADMIN_PORTAL_URL(state, url) {
      state.adminPortalLink = url
    },
  },
  actions: {
    setEntryUrl(context, entryUrl) {
      context.commit('SET_ENTRY_URL', entryUrl)
    },
    setFormError(context, formError) {
      context.commit('SET_FORM_ERROR', formError)
    },
    setLocale(context, value) {
      context.commit('SET_LOCALE', value)
    },
    async getContentDefault(context) {
      const locale = context.getters.locale

      let content = await import(
        /* webpackChunkName: "lang-[request]" */ `translations/mysungevity/${locale}.json`
      )

      if (context.getters.isB2B) {
        const b2b = await import(
          /* webpackChunkName: "b2b-[request]" */ `translations/b2b/${locale}.json`
        )
        content = {
          ...content,
          ...b2b,
        }
      }
      context.commit('SET_CONTENT', content)

      const faqs = await import(
        /* webpackChunkName: "faq-[request]" */ `translations/faq/${locale}.json`
      )
      context.commit('SET_FAQS', faqs)
    },
    async setUserLocale(context, locale) {
      const user = await Auth.currentUserPoolUser()
      context.commit('OVERWRITE_AUTHENTICATED_USER_DATA_LOCALE', locale)

      await Auth.updateUserAttributes(user, {
        locale,
      })
    },
    getScrollPosition(context) {
      return context.state.scrollPosition || 0
    },
  },
}
