<template>
  <span class="d-md-none">
    <div class="displayed-previous-button" @click="changePage(-1)" v-show="value > 0">
      <img src="@/assets/icons/back.svg" />
    </div>
    <div class="displayed-next-button" @click="changePage(1)" v-show="value < 2">
      <img src="@/assets/icons/forward.svg" />
    </div>
  </span>
</template>

<script>
export default {
  name: 'ScrollDateButtons',
  props: ['value'],
  methods: {
    changePage(n) {
      this.$store.commit('SET_INPUT_FIELDS', {
        page: this.value + n,
      })
    },
  },
}
</script>
