<template>
  <section class="mb-5">
    <div class="my-6 mx-md-5 mt-md-7">
      <canvas ref="chart"></canvas>
    </div>
  </section>
</template>

<script>
import Chart from 'chart.js'
import chartParameters from './location-deltas-graph-settings.js'
import { DateTime } from 'luxon'

const sungevityOrange = 'rgba(255, 103, 27)'
const sungevityOrangeFaded = 'rgba(251, 208, 188)'

export default {
  props: ['deltas'],
  data() {
    return {
      graph: null,
    }
  },
  computed: {
    datasets() {
      return {
        production: this.deltas.map((d) => d.productionDelta.kwhProduced),
        guarantee: this.deltas.map((d) => d.productionGuarantee),
      }
    },
    xLabels() {
      return this.deltas.map((d) =>
        DateTime.fromISO(d.intervalStartAt, { locale: this.$store.getters.language }).toFormat(
          'MMM'
        )
      )
    },
  },
  methods: {
    draw() {
      if (this.graph) this.updateGraph()
      else this.createGraph()
    },
    updateGraph() {
      const { production, guarantee } = this.datasets
      const backgroundColors = [sungevityOrange, sungevityOrangeFaded]
      const datasets = [production, guarantee].map((data, index) => ({
        label: '',
        data,
        backgroundColor: data.map(() => backgroundColors[index]),
        barPercentage: 1,
        categoryPercentage: 0.7,
      }))
      const labels = this.xLabels
      this.graph.data = {
        labels,
        datasets,
      }
      this.graph.update()
    },
    createGraph() {
      const ctx = this.$refs.chart
      const { production, guarantee } = this.datasets

      const chartParams = chartParameters({
        datasets: [production, guarantee],
        xAxisLabels: this.xLabels,
        backgroundColors: [sungevityOrange, sungevityOrangeFaded],
      })
      this.graph = new Chart(ctx, chartParams)
    },
  },
  watch: {
    deltas() {
      this.draw()
    },
  },
  mounted() {
    if (this.deltas) this.draw()
  },
}
</script>
