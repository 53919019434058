<template>
  <select class="custom-select" v-model="model">
    <option v-for="option in customerReferenceOptions" :value="option.value" :key="option.text">
      {{ option.text }}
    </option>
  </select>
</template>

<script>
export default {
  name: 'CustomerReferenceSelector',
  props: ['value', 'customerReferences'],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    customerReferenceOptions() {
      const allComplexes = {
        text: this.content.b2b_production_graph_all_complexes_dropdown_option,
        value: null,
      }

      if (!this.customerReferences) return [allComplexes]

      const customerReferences = this.customerReferences.map((customerReference) => ({
        text: `${this.content.b2b_customer_reference_prefix} ${customerReference}`,
        value: customerReference,
      }))
      return [allComplexes, ...customerReferences]
    },
  },
}
</script>
