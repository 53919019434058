<template>
  <div>
    <div class="w-100 text-center mb-4">
      <img alt="Password sent" src="@/assets/icons/monitoring-load-data.svg" />
      <h2
        class="card-title text-primary font-weight-bold"
        v-dynamic-content:monitoring_loading_data_title
      ></h2>
    </div>
    <p v-dynamic-content:monitoring_loading_data_text></p>
  </div>
</template>

<script>
export default {
  name: 'LoadingData',
}
</script>
