export default {
  computed: {
    content() {
      return this.$store.state.data.content
    },
    faqs() {
      return this.$store.state.data.faqs
    },
    headertext() {
      return this.$store.state.data.headertext
    },
  },
}
