import Vue from 'vue'

export default {
  state: {
    emailTemplate: '',
    emailSent: false,
  },
  mutations: {
    SET_EMAIL_TEMPLATE(state, emailTemplate) {
      state.emailTemplate = emailTemplate
    },
    RESET_EMAIL_SENT(state) {
      state.emailSent = false
    },
    EMAIL_SEND_SUCCESS(state) {
      state.emailSent = true
    },
  },
  actions: {
    sendReferrals(context, submission) {
      const url = '/v1/referrals'
      const payload = {
        referees: submission.referees,
        personalizedMessage: submission.personalizedText,
      }
      return Vue.$httpAuthorizedReferrals.post(url, payload).then((response) => {
        if (response.status === 200) {
          context.commit('EMAIL_SEND_SUCCESS')
        } else {
          context.commit('RESET_EMAIL_SENT')
        }
      })
    },
    setEmailTemplate(context) {
      const url = `/v1/templates/${context.getters.locale}`
      return Vue.$httpAuthorizedReferrals.get(url).then((response) => {
        context.commit('SET_EMAIL_TEMPLATE', response)
      })
    },
    resetEmailSent(context) {
      context.commit('RESET_EMAIL_SENT')
    },
  },
}
