<template>
  <div class="container card-full-width-mobile pb-md-9">
    <b-card tag="article" class="mt-8 mb-2">
      <section class="row justify-content-between py-6 px-0 p-md-8">
        <div class="col-12 col-lg-5 mb-3 mb-lg-0" v-if="$slots['side']">
          <slot name="side"></slot>
        </div>
        <div class="col-12 col-lg-5" v-if="$slots['form']">
          <slot name="form"></slot>
        </div>
      </section>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'FormTemplate',
  mounted() {
    this.$store.commit('SET_HEADER_TEXT', null)
  },
}
</script>
