<template>
  <b-col md="5" sm="12">
    <section class="categories-checkboxes pl-sm-0 pl-md-4 pl-lg-0">
      <b-form-group>
        <b-form-checkbox-group
          stacked
          class="production-only-checkbox"
          v-model="selectedCategories"
        >
          <div v-for="cb in availableCheckboxes" :key="cb.contentKey">
            <b-row class="no-gutters">
              <b-form-checkbox :value="cb.name" :disabled="cb.disabled" :class="cb.classList">
                {{ content[cb.contentKey] }} : {{ cb[selectedUnit] }}
                <a class="info-symbol ml-1" role="button" :data-info-popup="cb.infoPopup"></a>
              </b-form-checkbox>
            </b-row>
          </div>
        </b-form-checkbox-group>
      </b-form-group>
    </section>
  </b-col>
</template>

<script>
import { mapState, mapGetters } from 'vuex'

export default {
  name: 'SelectCategories',
  computed: {
    ...mapGetters(['totalProduction']),
    ...mapState({
      monitoring: ({ monitoring }) => monitoring,
      deltas: (state) => state.monitoring.deltas,
      selectedTariff: (state) => state.monitoring.inputs.tariff,
      selectedUnit: (state) => state.monitoring.inputs.unit,
    }),
    selectedCategories: {
      get() {
        return this.monitoring.inputs.categories
      },
      set(value) {
        this.$store.commit('SET_INPUT_FIELD', { key: 'categories', value })
      },
    },
    // What checkboxes should be visible
    availableCheckboxes() {
      const { electricity } = this.cbOptions
      return [electricity.production]
    },
    // All the display logic for each individual checkbox
    cbOptions() {
      return {
        electricity: {
          production: {
            name: 'electricityProduction',
            disabled: true,
            contentKey: 'monitoring_production_graph_key_category_produced',
            energy: this.$options.filters.formatKwh(this.totalProduction, 2),
            price: this.getPrice(this.totalProduction),
            classList: 'yellow-category-choice my-sm-2 my-md-0 mb-md-4',
            infoPopup: 'energyProductionKey',
          },
        },
      }
    },
  },
  methods: {
    getPrice(v) {
      return this.$options.filters.formatCurrency(v * this.selectedTariff, 2)
    },
  },
}
</script>
