<template>
  <div :class="{ loading }" class="d-flex justify-content-between">
    <div class="col-auto pl-0">
      <button
        @click="goToPage(page - 1)"
        v-if="page > 1"
        :disabled="loading"
        class="btn btn-sm btn-secondary"
        v-dynamic-content:b2b_locations_pagination_previous
      ></button>
    </div>

    <div class="col-auto pr-0">
      <button
        @click="goToPage(page + 1)"
        v-if="numberOfPages > page"
        :disabled="loading"
        class="btn btn-sm btn-secondary"
        v-dynamic-content:b2b_locations_pagination_next
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['loading', 'numberOfPages'],
  computed: {
    page() {
      return Number(this.$route.query.page) || 1
    },
  },
  methods: {
    goToPage(page) {
      this.$router.push({ name: this.$route.name, query: { page } })
    },
  },
}
</script>

<style lang="scss" scoped>
.btn-secondary,
.btn-secondary:hover,
.btn-secondary:active {
  color: white;
  border-radius: 5px;
}
</style>
