<template>
  <b-col md="7" sm="12">
    <b-row>
      <b-col lg="5" md="5" sm="5" class="my-auto pl-sm-0 pl-md-4 pl-lg-0 pr-0">
        <div class="switch-wrapper mt-5 mt-sm-1 pl-0 pl-sm-4 pl-lg-4 pr-sm-4 pr-md-0">
          {{
            selectedUtility === 'electricity'
              ? content.monitoring_production_graph_units_kwh
              : content.monitoring_production_graph_units_m3
          }}
          <label class="switch">
            <input type="checkbox" v-model="selectedUnit" />
            <span class="slider"></span>
          </label>
          {{ content.monitoring_production_graph_units_euro }}
        </div>
      </b-col>
      <b-col cols="12" class="mb-3" v-if="displayAsPrice">
        <div class="energy-tariff mb-md-6">
          <small class="mb-0">{{ content.monitoring_production_graph_energytariff_label }}</small>
          <div class="input-group d-flex mx-auto">
            <money
              v-model="selectedTariff"
              v-bind="moneySettings"
              type="text"
              id="uname"
              class="form-control w-50"
            ></money>
          </div>
          <b-form-invalid-feedback :class="{ 'd-block': $v.selectedTariff.$error }">
            <div v-if="!$v.selectedTariff.between" id="betweenError">
              {{ content.monitoring_production_graph_energytariff_error_highvalue }}
            </div>
          </b-form-invalid-feedback>
        </div>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import { Money } from 'v-money'
import { required, between } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  components: {
    Money,
  },
  data() {
    return {
      moneySettings: {
        decimal: ',',
        thousands: '.',
        prefix: '€ ',
        suffix: '',
        precision: 2,
        masked: false,
      },
    }
  },
  computed: {
    ...mapGetters(['displayAsPrice']),
    selectedUnit: {
      get() {
        return this.$store.state.monitoring.inputs.unit === 'price'
      },
      set(v) {
        this.$store.commit('SET_INPUT_FIELD', { key: 'unit', value: v ? 'price' : 'energy' })
      },
    },
    selectedUtility: {
      get() {
        return this.$store.state.monitoring.inputs.utility
      },
      set(value) {
        this.$store.commit('SET_INPUT_FIELD', { key: 'utility', value })
      },
    },
    selectedTariff: {
      get() {
        return this.$store.state.monitoring.inputs.tariff
      },
      set(value) {
        this.$store.commit('SET_INPUT_FIELD', { key: 'tariff', value })
      },
    },
  },
  validations: {
    selectedTariff: {
      required,
      between: between(0, 1),
    },
  },
}
</script>
