import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/app/store'
import PageError from '@/app/components/main/error/PageError'
import PageNotFound from '@/app/components/main/error/PageNotFound'
import Admin from '@/app/components/admin/Admin'
import { Auth } from 'aws-amplify'

const ifNotAuthenticated = async (to, from, next) => {
  if (store.getters.isAdmin) {
    return next()
  } else {
    try {
      await Auth.currentSession()
      return next('/')
    } catch {
      if (store.state.data.authenticatedUserData) {
        store.commit('SET_AUTHENTICATED_USER_DATA', null)
      }
      return next()
    }
  }
}

const ifAuthenticated = async (to, from, next) => {
  if (store.getters.isAdmin) {
    return next()
  } else {
    try {
      const session = await Auth.currentSession()
      if (!store.state.data.authenticatedUserData) {
        store.commit('SET_AUTHENTICATED_USER_DATA', session.idToken.payload)
      }

      if (store.getters.isB2B && !to.path.startsWith('/b2b')) {
        return next('/b2b')
      } else {
        return next()
      }
    } catch {
      store.dispatch('setEntryUrl', to.fullPath) // set the path that
      return next(Vue.types.pages.LOGIN)
    }
  }
}

export const routerGuards = {
  ifAuthenticated,
  ifNotAuthenticated,
}

export const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      beforeEnter: async (to, from, next) => {
        routerGuards.ifAuthenticated(to, from, async (nextTo) => {
          if (nextTo) next(nextTo)
          else {
            if (store.getters.isB2B) {
              next('/b2b')
            } else {
              next('/monitoring')
            }
          }
        })
      },
    },
    {
      path: Vue.types.pages.ERROR,
      component: PageError,
    },
    {
      path: '*',
      component: PageNotFound,
    },
    {
      path: Vue.types.pages.ADMIN,
      name: 'Admin',
      component: Admin,
    },
  ],
})

// scroll to top after each nav

router.afterEach(() => {
  setTimeout(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  })
})
