<template>
  <div class="row px-3 mb-4">
    <a
      v-for="(item, index) in availableLocales"
      :key="index"
      :class="{ 'col-2 list-unstyled p-0': true, 'font-weight-bold text-primary': item === locale }"
      @click="languageSwitch(item)"
    >
      {{ item | languageText }}
    </a>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'InlogLocaleSwitch',
  methods: {
    languageSwitch(newLang) {
      // do content call with new lang
      this.$store.commit('SET_LOCALE', newLang)
    },
  },
  computed: {
    ...mapGetters(['availableLocales', 'locale']),
  },
}
</script>
