<template>
  <span>
    <a
      class="px-3"
      v-for="(item, index) in availableLocales"
      :key="index"
      :class="{ 'font-weight-bold text-primary': item === locale }"
      @click="languageSwitch(item)"
      >{{ item | languageText }}</a
    >
  </span>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'localeSwitch',
  methods: {
    languageSwitch(newLang) {
      if (this.$store.getters.isAdmin) this.$store.dispatch('setAdminLanguage', newLang)
      else this.$store.dispatch('setUserLocale', newLang)
    },
  },
  computed: {
    ...mapGetters(['availableLocales', 'locale']),
  },
}
</script>
