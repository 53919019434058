<template>
  <div class="container mt-7 pb-9">
    <div class="row">
      <div class="col col-sm-12 col-lg-4 mb-7">
        <locations-online-block></locations-online-block>
      </div>
      <div class="col col-sm-12 col-lg-8 mb-7">
        <production-graph-block></production-graph-block>
      </div>

      <div class="col-12">
        <environmental-benefits-block></environmental-benefits-block>
      </div>
    </div>
  </div>
</template>

<script>
import LocationsOnlineBlock from './LocationsOnlineBlock'
import EnvironmentalBenefitsBlock from './EnvironmentalBenefitsBlock'
import ProductionGraphBlock from './ProductionGraphBlock'

export default {
  components: {
    LocationsOnlineBlock,
    EnvironmentalBenefitsBlock,
    ProductionGraphBlock,
  },
  mounted() {
    this.$store.dispatch('fetchLocationGroup')
    this.$store.commit(
      'SET_HEADER_TEXT',
      this.content.b2b_welcome_header &&
        this.content.b2b_welcome_header.replace('{0}', this.$store.getters.profile.firstName)
    )
  },
}
</script>
