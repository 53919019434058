<template>
  <div v-if="fullscreen === true">
    <div class="loader">
      <div class="loader-spinner"></div>
    </div>
  </div>
  <div v-else>
    <div class="loader-spinner"></div>
  </div>
</template>

<script>
export default {
  name: 'loader',
  props: {
    fullscreen: Boolean,
  },
}
</script>
