import Vue from 'vue'
import getLocations from '@/app/graphql/getLocations'
import getDeltas from '@/app/graphql/getDeltas'
import { DateTime } from 'luxon'

//helpers

//check if this is an object we can key into
const isObj = (a) => a && typeof a === 'object' && Object.keys(a).length
// safely descend obj down path
const at = (path) => (obj) => path.reduce((acc, cv) => (isObj(acc) ? acc[cv] : undefined), obj)
// sum array of maybe numbers
const safeSum = (xs) => xs.reduce((c, n) => (n && typeof n === 'number' ? c + n : c), 0)
const apiCache = {}

const barSettings = {
  year: {
    step: 'months',
    format: 'MMM',
  },
  month: {
    step: 'days',
    format: 'd',
  },
  week: {
    step: 'days',
    format: 'ccc d LLL',
  },
  day: {
    step: 'hours',
    format: 'HH',
  },
}

export default {
  state: {
    // user defined state
    inputs: {
      // although it is appealing for ease of use, do not store DateTime objects in Vuex
      // see https://vuex.vuejs.org/guide/state.html & https://vuejs.org/v2/api/#data
      // > A rule of thumb is that data should just be data - it is not recommended to observe objects with their own stateful behavior.
      intervalStartAt: '',
      interval: 'week',
      utility: 'electricity',
      unit: 'energy',
      tariff: 0.28,
      page: 0,
    },
    // backend state
    location: {},
    deltas: [],
    // errors
    error: '',
    loading: {
      getDeltas: false,
    },
    initialized: false,
  },
  getters: {
    // convenience getters. Allows components to focus on display logic
    monitoringUrl: (_s, _g, { data }) => data.baseUrl['monitoring-platform-api'],
    location: (state) => state.location,
    onlineSince: ({ location }) => location.onlineSince,
    firstReadingAt: ({ location }) => location.firstReadingAt,
    displayAsPrice: (state) => state.inputs.unit === 'price',
    displayAsEnergy: (state) => state.inputs.unit === 'energy',
    installationFound: (state) => state.location && Object.keys(state.location).length,
    barSettings: ({ inputs: { interval } }) => barSettings[interval],
    xAxisDates: ({ inputs: { interval, intervalStartAt } }, { barSettings }) => {
      const dates = []
      const { step } = barSettings

      // to compare days, we base ourselves on UTC times
      const start =
        step === 'hours'
          ? DateTime.fromISO(intervalStartAt)
          : DateTime.fromISO(intervalStartAt, { zone: 'UTC' })

      const end = start.plus({ [interval]: 1 })
      let lowerBound = start.startOf(step)
      while (lowerBound < end) {
        dates.push(lowerBound.toISO())
        lowerBound = lowerBound.plus({ [step]: 1 })
      }

      return dates
    },
    selectDeltas: ({ deltas, inputs }, { xAxisDates, showProductionSpikeWarning }) => {
      const compareDelta = (delta, date) => {
        return DateTime.fromISO(date).equals(DateTime.fromISO(delta.intervalStartAt))
      }
      const isInitialSpike =
        showProductionSpikeWarning && deltas.length && deltas[0].productionDelta.kwhProduced > 10
      const visibleDeltas = isInitialSpike && inputs.interval !== 'year' ? deltas.slice(1) : deltas
      return xAxisDates.map((date) => visibleDeltas.find((d) => compareDelta(d, date)))
    },
    electricityProduction: (_, { selectDeltas }) =>
      selectDeltas.map((d) => {
        const n = d && d.productionDelta && d.productionDelta.kwhProduced
        return n < 0 ? 0 : n
      }),
    totalProduction: (_, getters) => safeSum(getters.electricityProduction),
    visibleDeltas: () => ['electricityProduction'],
    formattedDateRange: ({ inputs }) => {
      const intervalStartAt = inputs.intervalStartAt
      return {
        intervalStartAt,
        intervalEndAt: DateTime.fromISO(intervalStartAt)
          .plus({ [inputs.interval]: 1 })
          .toISODate(),
      }
    },
    barInterval: ({ inputs }) =>
      ({
        day: 'HOUR',
        week: 'DAY',
        month: 'DAY',
        year: 'MONTH',
      }[inputs.interval]),
    deltaQueryInput: ({ location }, { formattedDateRange, barInterval }) => ({
      ...formattedDateRange,
      unitOfTime: barInterval,
      locationId: location.id,
    }),
    showSolarmanPre2021Warning: ({ inputs }, { brands }) => {
      return (
        DateTime.fromISO(inputs.intervalStartAt).year < 2021 &&
        inputs.interval !== 'year' &&
        brands.find((brand) => brand === 'Solis' || brand === 'Omnik')
      )
    },
    brands: ({ location }) => {
      return location.meteringSystems.map((ms) => ms.brand)
    },
    noDataForDateRange: ({ deltas }) => !deltas.length,
    showProductionSpikeWarning: ({ location, inputs }) => {
      const onlineSince = DateTime.fromISO(location.onlineSince)
      const firstReadingAt = DateTime.fromISO(location.firstReadingAt)
      const intervalStartAt = DateTime.fromISO(inputs.intervalStartAt)
      const intervalEndAt = intervalStartAt.plus({ [inputs.interval]: 1 })
      return (
        firstReadingAt.diff(onlineSince).as('days') > 3 &&
        firstReadingAt >= intervalStartAt &&
        firstReadingAt <= intervalEndAt
      )
    },
    initialReadingKwhProduced: ({ deltas, inputs }, { showProductionSpikeWarning }) => {
      // Sometimes the first reading we receive from the meter is very high
      // To protect against this we show some banners explaining to the customer
      // why this is, and remove the interval with the spike in production as it
      // causes a lot of confusion in the UI
      if (inputs.interval === 'year' || !deltas[0]) return
      const firstReading =
        deltas && deltas.sort((d1, d2) => d1.intervalStartAt - d2.intervalStartAt)[0]
      if (showProductionSpikeWarning && firstReading.productionDelta.kwhProduced > 10)
        return firstReading.productionDelta.kwhProduced
      else return
    },
    showNegativeProductionWarning: ({ deltas }) =>
      deltas.map(at(['productionDelta', 'kwhProduced'])).some((delta) => delta < 0),
  },
  mutations: {
    SET_LOADING(state, { action, value }) {
      state.loading[action] = value
    },
    SET_INPUT_FIELD(state, { key, value }) {
      state.inputs[key] = value
    },
    SET_INPUT_FIELDS(state, set) {
      state.inputs = {
        ...state.inputs,
        ...set,
      }
    },
    SET_DELTAS(state, deltas) {
      state.deltas = deltas
    },
    SET_LOCATION(state, location) {
      state.location = location
      state.initialized = true
    },
    SET_ERROR(state, error) {
      state.error = error
    },
    LOGOUT(state) {
      state.location = {}
      state.initialized = false
    },
  },
  actions: {
    // convenience helper for keeping track
    // of arbitrary loading states. Can be passed any
    // known action and toggles a boolean
    // for that action in the loading field.
    async wrapLoading({ commit, dispatch }, { action, args }) {
      try {
        commit('SET_LOADING', { action, value: true })
        await dispatch(action, args)
      } finally {
        commit('SET_LOADING', { action, value: false })
      }
    },
    async callApi({ getters }, body) {
      const s = JSON.stringify(body)
      if (apiCache[s]) {
        return { data: apiCache[s] }
      }
      const { data, errors } = await Vue.$httpAuthorizedMonitoringPlatform.post(
        getters.monitoringUrl,
        body
      )
      if (data && !errors) apiCache[s] = data
      return { data, errors }
    },
    async getLocation({ dispatch, commit, getters }) {
      try {
        const { accountNumber } = getters.profile
        const body = getLocations(Number(accountNumber))
        const { data } = await dispatch('callApi', body)
        if (!data) throw `LOCATION FETCH ERROR`
        const location = data.data.getLocations.nodes[0]

        if (location) {
          const lastReadingAt = location.lastReadingAt
            ? DateTime.fromISO(location.lastReadingAt)
            : DateTime.now()
          commit('SET_INPUT_FIELDS', {
            intervalStartAt: lastReadingAt.minus({ day: 1 }).startOf('week').toISODate(),
          })
          commit('SET_LOCATION', location)
        }
      } catch (error) {
        console.error(`Error while fetching location`, error.message)
        commit('SET_ERROR', error.message)
      }
    },
    async getDeltas({ commit, dispatch, getters }) {
      try {
        const body = getDeltas(getters.deltaQueryInput)
        const { data } = await dispatch('callApi', body)
        if (data) return commit('SET_DELTAS', at(['data', 'getDeltas'])(data))
        throw `DELTAS ERROR`
      } catch (error) {
        console.error(error.message)
        commit('SET_ERROR', error)
      }
    },
    setGraphView(context, energyType) {
      context.commit('SET_GRAPH_VIEW', energyType)
    },
    clearLocation(context) {
      context.commit('SET_LOCATION', '')
    },
  },
}
