const chartParameters = ({ datasets, xAxisLabels, backgroundColors }) => ({
  type: 'bar',
  data: {
    labels: xAxisLabels,
    datasets: datasets.map((data, index) => ({
      label: '',
      data,
      backgroundColor: data.map(() => backgroundColors[index]),
      barPercentage: 1,
      categoryPercentage: 0.7,
    })),
  },
  options: {
    legend: {
      display: false,
    },
    responsive: true,
    aspectRatio: 1.1,
    //maintain the original canvas aspect ratio (width / height) when resizing.
    maintainAspectRatio: false,
    scales: {
      xAxes: [
        {
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 9,
            fontFamily: 'museo-sans',
            fontColor: '#485465',
            fontStyle: 300,
            fontSize: 10,
          },
        },
      ],
      yAxes: [
        {
          gridLines: {
            drawBorder: false,
            lineWidth: 2,
            zeroLineColor: '#3f3f45',
          },
          ticks: {
            maxTicksLimit: 7,
            beginAtZero: true,
            fontFamily: 'museo-sans',
            fontColor: '#485465',
            fontStyle: 300,
            fontSize: 10,
            padding: 9,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem) {
          const format = (n) => Intl.NumberFormat('nl-NL', { maximumFractionDigits: 0 }).format(n)
          const value = Number(tooltipItem.value)
          return `${format(value)} kWh`
        },
      },
      backgroundColor: '#ffffff',
      titleFontColor: '#000000',
      borderColor: '#000000',
      borderWidth: '1',
      bodyFontColor: '#000000',
      displayColors: false,
    },
  },
})

export default chartParameters
