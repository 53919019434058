<template lang="html">
  <div>
    <p v-dynamic-content="{ key: 'create_new_password_validation_description' }"></p>
    <ul class="mb-0">
      <li v-for="rule in rules" :key="rule">
        {{ rule }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'PasswordRules',
  computed: {
    rules() {
      return ['length', 'letters', 'number'].map(
        (rule) => this.content[`create_new_password_validation_${rule}_rule`]
      )
    },
  },
}
</script>
