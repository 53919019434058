export default function (variables) {
  return {
    query: `query getDeltas(
      $locationId: Int!
      $intervalStartAt: AWSDate!
      $intervalEndAt: AWSDate!
      $unitOfTime: UnitOfTime!
    ) {
      getDeltas(
        intervalStartAt: $intervalStartAt,
        intervalEndAt: $intervalEndAt,
        unitOfTime: $unitOfTime,
        locationId: $locationId) {
          intervalStartAt
          productionDelta {
            kwhProduced
          }
      }
    }`,
    variables,
  }
}
