<template>
  <select class="custom-select" v-model="model">
    <option :value="null">{{ content.b2b_month_selector_all_months_option }}</option>
    <option v-for="(month, index) in months" :value="index + 1" :key="index">{{ month }}</option>
  </select>
</template>

<script>
import { DateTime } from 'luxon'

export default {
  name: 'MonthSelector',
  props: ['value'],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    months() {
      const locale = this.$store.getters.locale.replace('_', '-')
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(
        (month) => DateTime.fromObject({ month, locale }).monthLong
      )
    },
  },
}
</script>
