<template>
  <div class="m-md-5 mb-md-7">
    <div class="progress mb-2 progress-empty-bg">
      <div
        class="progress-bar bg-primary"
        role="progressbar"
        :style="{ width: progress }"
        aria-valuenow="100"
        aria-valuemin="0"
        aria-valuemax="100"
      ></div>
    </div>
    <div class="d-flex justify-content-end mb-4">
      <small
        v-dynamic-content:b2b_production_graph_progress_bar_description="[currentYear, progress]"
      ></small>
    </div>
  </div>
</template>

<script>
export default {
  props: ['progress', 'currentYear'],
}
</script>

<style lang="scss" scoped>
.progress-empty-bg {
  background-color: $gray-100;
}
</style>
