<template>
  <div v-if="profile" class="profile-menu">
    <!-- LG and bigger - render a new dropdown -->
    <b-dropdown right v-if="$mq === 'xl'" id="profile" class="btn ml-auto dropdown-profile">
      <template slot="button-content">
        <div class="profileDropdown" ref="toggleProfileMenu"></div>
      </template>
      <profile-menu-content></profile-menu-content>
    </b-dropdown>

    <!-- MD and smaller - only render dropdown-menu-items -->
    <div v-else>
      <b-dropdown-divider></b-dropdown-divider>
      <profile-menu-content></profile-menu-content>
    </div>
  </div>
</template>

<script>
import ProfileMenuContent from './ProfileMenuContent'

export default {
  name: 'ProfileMenu',
  mixins: [],
  components: {
    ProfileMenuContent,
  },
}
</script>
