<template>
  <div class="container mt-8 pb-8">
    <div
      class="mb-6 d-flex flex-row input-group input-group-lg justify-content-between align-items-center"
    >
      <div class="col text-right pt-4" v-dynamic-content:b2b_locations_list_filter_label></div>
      <div class="col-auto pl-0 pr-0 pr-lg-4 pt-4">
        <year-selector
          allowAll="true"
          :onlineSince="getLocationGroup.onlineSince"
          v-model="filterInput.year"
        ></year-selector>
      </div>
      <div class="col-auto pl-0 pr-0 pr-lg-4 pt-4">
        <month-selector v-model="filterInput.month" :disabled="!filterInput.year"></month-selector>
      </div>
      <div class="col-6 col-md-3 px-0 ml-auto ml-lg-0 pt-4 pl-0">
        <customer-reference-selector
          v-model="filterInput.customerReference"
          :customerReferences="getLocationGroup.customerReferences"
        ></customer-reference-selector>
      </div>
    </div>

    <div class="card overflow-auto">
      <div
        class="d-flex row justify-content-between align-items-center"
        :class="{ loading }"
        v-if="getLocations.nodes"
      >
        <div
          v-dynamic-content:b2b_locations_header_locations_found="[totalLocationsFormatted]"
          class="col-auto"
        ></div>
        <div class="col-auto">
          <button
            class="btn btn-primary export d-flex"
            @click="exportToCsv"
            :disabled="getLocations.totalCount < 1"
          >
            <b-spinner class="flex spinner-border-sm mr-2" v-if="loadingExport"></b-spinner>
            <span v-dynamic-content:b2b_production_list_export_button></span>
          </button>
        </div>
      </div>

      <div class="table-container">
        <b-spinner v-if="loading"></b-spinner>

        <table :class="{ loading }" class="mt-6 mb-6">
          <thead>
            <tr>
              <th width="30%" v-dynamic-content:b2b_locations_table_column_address></th>
              <th width="20%" v-dynamic-content:b2b_locations_table_column_city></th>
              <th width="20%" v-dynamic-content:b2b_locations_table_column_complex_number></th>
              <th width="15%" v-dynamic-content:b2b_locations_table_column_interval></th>
              <th width="15%" v-dynamic-content:b2b_production_table_production_in_kwh_column></th>
            </tr>
          </thead>
          <tbody v-if="getLocations.nodes">
            <tr v-for="location in getLocations.nodes" :key="location.id">
              <td>{{ location.address }}</td>
              <td>{{ location.city }}</td>
              <td>{{ location.customerReference }}</td>
              <td>
                <!-- for all years: show year interval -->
                <div v-if="!filterInput.year">
                  {{ fullPeriod(location) }}
                </div>
                <!-- for specific year/month -->
                <div v-else-if="filterInput.month">
                  {{ localizedMonthFormat }}
                </div>
                <!-- for specific year: show specific year -->
                <div v-else>
                  {{ filterInput.year }}
                </div>
              </td>
              <td>
                <span v-if="!loading && location.productionDelta">
                  {{ formatKwh(location.productionDelta.kwhProduced) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <pagination-buttons :numberOfPages="numberOfPages" :loading="loading"></pagination-buttons>
      </div>
    </div>
  </div>
</template>

<script>
import YearSelector from '../common/YearSelector'
import gql from 'graphql-tag'
import { DateTime } from 'luxon'
import CustomerReferenceSelector from '@/app/components/b2b/common/CustomerReferenceSelector'
import { formatNumber } from '@/app/filters/formatNumbers'
import PaginationButtons from '@/app/components/b2b/common/PaginationButtons'
import MonthSelector from '../common/MonthSelector'

const locationsPerPage = 30
// this is limited and enforced in the backend, just here to be customer-friendly
const exportLimit = 10000

export default {
  data() {
    return {
      getLocations: '',
      loadingExport: false,
      getLocationGroup: '',
      filterInput: {
        customerReference: null,
        year: DateTime.local().year,
        month: null,
      },
    }
  },
  components: {
    YearSelector,
    MonthSelector,
    CustomerReferenceSelector,
    PaginationButtons,
  },
  computed: {
    loading() {
      return this.$apollo.loading
    },
    page() {
      // we use human-friendly starting-at-1 page numbers in the URL
      return Number(this.$route.query.page) || 1
    },
    numberOfPages() {
      return Math.ceil(this.getLocations.totalCount / locationsPerPage)
    },
    totalLocationsFormatted() {
      return formatNumber(this.getLocations.totalCount)
    },
    intervalStartEndDates() {
      // if year is not set both are null
      if (!this.filterInput.year) {
        return [null, null]
      }
      // if year and month are set
      else if (this.filterInput.month) {
        const date = DateTime.fromObject({
          year: this.filterInput.year,
          month: this.filterInput.month,
          zone: 'UTC',
        })
        return [date.toISODate(), date.endOf('month').toISODate()]
      }
      // if only year is set return the year span
      else {
        const date = DateTime.fromObject({ year: this.filterInput.year, zone: 'UTC' })
        return [date.toISODate(), date.endOf('year').toISODate()]
      }
    },
    filter() {
      if (!this.getLocationGroup) return null
      return {
        locationGroupId: this.getLocationGroup.id,
        customerReference: this.filterInput.customerReference,
      }
    },
    localizedMonthFormat() {
      return DateTime.fromObject({
        year: this.filterInput.year,
        month: this.filterInput.month,
      })
        .setLocale(this.$store.getters.language)
        .toFormat('MMM y')
    },
  },
  methods: {
    fullPeriod(location) {
      const startYear = DateTime.fromISO(location.onlineSince).year
      const currentYear = DateTime.local().year
      if (startYear === currentYear) return currentYear
      return `${startYear || '???'}-${currentYear}`
    },
    goToPage(page) {
      if (page !== this.page) {
        this.$router.push({ name: 'B2BProductionOverview', query: { page } })
      }
    },
    formatKwh(n) {
      return `${formatNumber(n)} kWh`
    },
    async exportToCsv() {
      if (this.getLocations.totalCount > exportLimit) {
        alert(
          this.content.b2b_maximum_export_of_n_rows_error.replace('{0}', formatNumber(exportLimit))
        )
        return
      }

      // would be great handle loading via Apollo, but I haven't found a way so far...
      this.loadingExport = true
      const {
        data: { exportLocationProductionDeltasToCsv: result },
      } = await this.$apollo.mutate({
        mutation: gql`
          mutation exportLocationProductionDeltasToCsv(
            $filter: LocationsFilter!
            $intervalYear: Int
          ) {
            exportLocationProductionDeltasToCsv(filter: $filter, intervalYear: $intervalYear) {
              success
              signedUrl
              message
            }
          }
        `,
        variables: {
          filter: this.filter,
          intervalYear: this.filterInput.year,
        },
      })
      this.loadingExport = false

      if (result.success) {
        location.href = result.signedUrl
      } else {
        alert(`An error occured: ${result.message}`)
      }
    },
  },
  apollo: {
    getLocations: {
      query: gql`
        query getLocations(
          $filter: LocationsFilter
          $limit: Int!
          $offset: Int!
          $intervalStartAt: AWSDate
          $intervalEndAt: AWSDate
        ) {
          getLocations(filter: $filter, limit: $limit, offset: $offset) {
            totalCount
            nodes {
              id
              address
              city
              onlineSince
              lastReadingAt
              customerReference
              solarModule {
                numberOfModules
                nameOfModule
              }
              productionDelta(intervalStartAt: $intervalStartAt, intervalEndAt: $intervalEndAt) {
                kwhProduced
              }
            }
          }
        }
      `,
      variables() {
        const [intervalStartAt, intervalEndAt] = this.intervalStartEndDates
        return {
          filter: this.filter,
          limit: locationsPerPage,
          offset: (this.page - 1) * locationsPerPage,
          intervalStartAt,
          intervalEndAt,
        }
      },
      skip() {
        return !this.filter
      },
    },
    getLocationGroup: {
      query: gql`
        query getLocationGroup($cognitoGroupName: String!) {
          getLocationGroup(cognitoGroupName: $cognitoGroupName) {
            id
            friendlyName
            totalCo2KgSaved
            onlineSince
            customerReferences
          }
        }
      `,
      variables() {
        return {
          cognitoGroupName: this.$store.getters.companyCognitoGroupName,
        }
      },
      skip() {
        return !this.$store.getters.companyCognitoGroupName
      },
    },
  },
  watch: {
    filterInput: {
      deep: true,
      handler(val, oldValue) {
        if (val.year === null && val.month !== null) {
          this.filterInput.month = null
        }

        // we reset the pagination when the customer reference / complex number filter is changed
        if (val.customerReference !== oldValue.customerReference) {
          this.goToPage(1)
        }
      },
    },
    numberOfPages(val) {
      if (this.page > val) {
        this.goToPage(1)
      }
    },
  },
  mounted() {
    this.$store.commit('SET_HEADER_TEXT', '')
  },
}
</script>

<style lang="scss" scoped>
.card {
  padding: 24px;
}
table {
  width: 100%;
}

td,
th {
  padding: 0.4em;
}
thead {
  font-size: 1em;
  font-weight: 500;
}
tbody td {
  font-size: 0.8em;
  border-top: 1px solid #dddddd;
}
.loading {
  opacity: 0.3;
}
.table-container {
  position: relative;
}
.table-container .spinner-border {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1em;
  z-index: 10;
}

.spinner-border {
  color: $brand-color;
}
button .spinner-border {
  color: $chart-grey;
}
</style>
