import { Auth } from 'aws-amplify'
import Axios from 'axios'
import config from 'config'
import { DateTime } from 'luxon'

async function queryMonitoringPlatform(body) {
  const response = await Auth.currentSession()
  if (response && response.idToken && response.idToken.jwtToken) {
    const token = response.idToken.jwtToken

    return Axios.post(config.endpoints['monitoring-platform-api'], body, {
      headers: {
        Authorization: token,
      },
    })
  } else {
    throw new Error('No JWT token found')
  }
}

export default {
  state: {
    locationGroup: 'not_set',
    environmentalImpactYear: 'not_set',
    environmentalImpactCustomerReference: 'not_set',
    locationGroupDeltas: 'not_set',
    locationGroupDeltasInput: null,
  },
  mutations: {
    LOGOUT(state) {
      state.locationGroup = 'not_set'
      state.environmentalImpactYear = 'not_set'
      state.environmentalImpactCustomerReference = 'not_set'
      state.locationGroupDeltas = 'not_set'
      state.locationGroupDeltasInput = null
    },
    SET_LOCATION_GROUP(state, { locationGroup, year }) {
      state.locationGroup = locationGroup
      state.environmentalImpactYear = year
      // On initial load we show all complexes
      state.environmentalImpactCustomerReference = null
    },
    SET_LOCATION_GROUP_DELTAS(state, { locationGroupDeltas, input }) {
      state.locationGroupDeltas = locationGroupDeltas
      state.locationGroupDeltasInput = input
    },
    SET_ENVIRONMENTAL_IMPACT(state, { environmentalImpact, year, customerReference }) {
      state.locationGroup = {
        ...state.locationGroup,
        environmentalImpact,
      }
      state.environmentalImpactYear = year
      state.environmentalImpactCustomerReference = customerReference
    },
  },
  getters: {
    companyName: (state) => state.locationGroup && state.locationGroup.friendlyName,
    locationGroup: (state) => state.locationGroup,
  },
  actions: {
    async fetchLocationGroup({ state, getters, commit }) {
      if (state.locationGroup !== 'not_set') return

      const group = getters.companyCognitoGroupName
      const year = DateTime.local().year
      const result = await queryMonitoringPlatform({
        query: `query getLocationGroup($cognitoGroupName: String!, $year: Int!) {
          getLocationGroup(cognitoGroupName: $cognitoGroupName) {
            id
            friendlyName
            locationCount
            meteringSystemCountGroupedByStatus {
              online
              offline
              inactive
            }
            totalCo2KgSaved
            onlineSince
            totalProductionDelta {
              kwhProduced
            }
            environmentalImpact(year: $year) {
              treesSaved
              carKmSaved
              co2KgSaved
            }
            customerReferences
          }
        }`,
        variables: {
          cognitoGroupName: group,
          year,
        },
      })

      if (result.status === 200 && result.data.data.getLocationGroup) {
        commit('SET_LOCATION_GROUP', {
          locationGroup: result.data.data.getLocationGroup,
          year,
        })
      }
    },
    async fetchLocationGroupDeltas(
      { state, commit },
      { locationGroupId, intervalStartAt, intervalEndAt, customerReference }
    ) {
      if (
        state.locationGroupDeltas !== 'not_set' &&
        intervalStartAt === state.locationGroupDeltasInput.intervalStartAt &&
        intervalEndAt === state.locationGroupDeltasInput.intervalEndAt &&
        customerReference === state.locationGroupDeltasInput.customerReference
      )
        return

      const result = await queryMonitoringPlatform({
        query: `query getMonthlyDeltas($filter: MonthlyDeltasFilter!) {
          getMonthlyDeltas(filter: $filter) {
            totalProductionGuarantee
            totalProductionDelta {
              kwhProduced
            }
            deltas {
              intervalStartAt
              productionDelta {
                kwhProduced
              }
              productionGuarantee
            }
          }
        }`,
        variables: {
          filter: {
            locationGroupId,
            intervalStartAt,
            intervalEndAt,
            customerReference,
          },
        },
      })

      if (result.status === 200 && result.data.data.getMonthlyDeltas) {
        commit('SET_LOCATION_GROUP_DELTAS', {
          locationGroupDeltas: result.data.data.getMonthlyDeltas,
          input: {
            locationGroupId,
            intervalStartAt,
            intervalEndAt,
            customerReference,
          },
        })
      }
    },
    async fetchEnvironmentalImpactData({ getters, commit }, { year, customerReference }) {
      const group = getters.companyCognitoGroupName
      const result = await queryMonitoringPlatform({
        query: `query getEnvironmentalImpactData($cognitoGroupName: String!, $year: Int!, $customerReference: String) {
          getLocationGroup(cognitoGroupName: $cognitoGroupName) {
            environmentalImpact(year: $year, customerReference: $customerReference) {
              treesSaved
              carKmSaved
              co2KgSaved
            }
          }
        }`,
        variables: {
          cognitoGroupName: group,
          year,
          customerReference,
        },
      })

      if (result.status === 200 && result.data.data.getLocationGroup.environmentalImpact) {
        commit('SET_ENVIRONMENTAL_IMPACT', {
          environmentalImpact: result.data.data.getLocationGroup.environmentalImpact,
          year,
          customerReference,
        })
      }
    },
  },
}
