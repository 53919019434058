export default function (accountNumber) {
  return {
    query: `query getLocations($accountNumber: Int!) {
      getLocations(filter: {accountNumber: $accountNumber}, limit: 1, offset: 0) {
        nodes {
          id
          onlineSince
          lastReadingAt
          firstReadingAt
          address
          productionDelta {
            kwhProduced
            environmentalImpact {
              co2KgSaved
              treesSaved
              carKmSaved
            }
          }
          meteringSystems {
            brand
          }
        }
      }
    }`,
    variables: {
      accountNumber,
    },
  }
}
