<template>
  <select class="custom-select" v-model="model">
    <option :value="null" v-if="allowAll">{{ content.b2b_year_selector_all_years_option }}</option>
    <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
  </select>
</template>

<script>
import { DateTime } from 'luxon'

const currentYear = DateTime.local().year

export default {
  name: 'YearSelector',
  props: ['value', 'onlineSince', 'allowAll'],
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    // available years for drop down since start date year
    years() {
      if (this.onlineSince) {
        const yearFirstOnline = DateTime.fromISO(this.onlineSince).year
        const yearOffset = currentYear - yearFirstOnline

        return [...Array(yearOffset + 1).keys()].map((i) => currentYear - i)
      } else {
        return [currentYear]
      }
    },
  },
}
</script>
