<template>
  <form-template>
    <template slot="form">
      <h2 class="mb-6">Login</h2>
      <b-container>
        <b-row>
          <b-col sm="12" md="6">
            <b-button
              class="btn-block"
              @click="login"
              variant="primary"
              v-dynamic-content:login_button_login
            ></b-button>
          </b-col>
          <b-col sm="12" md="6">
            <a :href="adminPortalLink">Return to Admin Portal</a>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </form-template>
</template>

<script>
import { Auth } from 'aws-amplify'

export default {
  name: 'AdminLogin',
  methods: {
    login() {
      Auth.federatedSignIn({ provider: 'Google' })
    },
  },
  computed: {
    adminPortalLink() {
      return this.$store.state.data.adminPortalLink
    },
  },
}
</script>
