import config from 'config'
import { router, routerGuards } from '@/app/router'

import Referrals from '@/app/components/customer/referrals/Referrals'
import ReferralPage from '@/app/components/customer/referrals/referralPage/ReferralPage'

if (
  config['/sg/my-sungevity/referrals-enabled'] &&
  config['/sg/my-sungevity/referrals-enabled'] > 0
) {
  router.addRoute({
    path: '/referrals',
    name: 'referrals',
    component: Referrals,
    beforeEnter: routerGuards.ifAuthenticated,
  })
  router.addRoute('referrals', {
    path: '',
    redirect: 'referralForm',
  })
  router.addRoute('referrals', {
    path: 'referralForm',
    name: 'ReferralPage',
    component: ReferralPage,
    beforeEnter: routerGuards.ifAuthenticated,
  })
}
