// toB2BMeteringSystemStatus is there to help
// in the transition period from binairy statuses online/offline
// to the actual location statuses 'Functioning', 'never connected', etc.
// i decided not to implement this right away to not further delay this ticket

const malfunctioningMeterStatus = [
  'Never connected',
  'Recently disconnected',
  'Zero production measured from beginning',
  'Zero production measured recently',
  'Faulty production measured recently',
]

export function toB2BMeteringSystemStatus(meteringSystem) {
  if (meteringSystem.status === 'Functioning') return 'online'
  else if (malfunctioningMeterStatus.includes(meteringSystem.status)) return 'offline'
  else return 'inactive'
}
