<template>
  <div class="row justify-content-between">
    <div class="col mb-6 mb-md-0">
      <strong v-dynamic-content:b2b_production_graph_visual_header></strong>
    </div>
    <div class="col-12 col-md-auto ml-auto my-4">
      <div class="row">
        <span class="label"></span>
        <p
          class="mr-5 mb-0 label-text"
          v-dynamic-content:b2b_production_graph_total_production="[productionFormatted]"
        ></p>
      </div>
      <div class="row">
        <span class="label-guarantee"></span>
        <p
          class="mr-5 mb-0 label-text"
          v-dynamic-content:b2b_production_graph_guaranteed_production="[guaranteeFormatted]"
        ></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['production', 'guarantee'],
  computed: {
    productionFormatted() {
      if (this.production === undefined) return '...'
      return this.$options.filters.formatKwh(this.production)
    },
    guaranteeFormatted() {
      if (this.guarantee === undefined) return '...'
      return this.$options.filters.formatKwh(this.guarantee)
    },
  },
}
</script>

<style scoped lang="scss">
.label,
.label-guarantee {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin: 2px 10px 0 15px;
  border-radius: 4.2px;
}

.label {
  background-color: $brand-color;
}

.label-guarantee {
  background-color: rgb(251, 208, 188);
}

.label-text {
  font-size: 14px;
  color: #3f3f45;
}

strong {
  font-size: 15px;
  font-weight: 600;
}
</style>
