<template>
  <section class="proposal">
    <page-error v-if="error"></page-error>
    <div
      v-if="maintenanceOngoing"
      class="container container-full-width-small container card-full-width-mobile mt-3 mt-md-9 p-0"
    >
      <maintenance-mode></maintenance-mode>
    </div>
    <div v-else class="container container-full-width-small">
      <div v-if="profile">
        <router-view></router-view>
      </div>
      <div v-else>
        <loader></loader>
      </div>
    </div>
  </section>
</template>

<script>
import PageError from '@/app/components/main/error/PageError'
import { mapState } from 'vuex'
import MaintenanceMode from '@/app/components/main/maintenance/MaintenanceMode'
export default {
  name: 'monitoring',
  components: {
    PageError,
    MaintenanceMode,
  },
  computed: {
    // content.maintenance_is_ongoing is set to true in phrase app if we want to go into "maintenance mode"
    // for work any work on our monitoring db or any other reason we would want to hide the monitoring section.
    // nl_NL, nl_BE, and fr_BE need to be set in phrase app if we want all language's to see the same message
    maintenanceOngoing() {
      return this.content.maintenance_is_ongoing === 'true'
    },
    ...mapState({
      error: (state) => state.monitoring.error,
    }),
  },
}
</script>
