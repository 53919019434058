<template>
  <div class="container">
    <div class="card" v-if="pagenotfoundText">
      <div class="card-body">
        {{ pagenotfoundText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageNotFound',
  computed: {
    pagenotfoundText() {
      return this.$store.state.data.content.error_pagenotfound_body_text || null
    },
  },
  mounted() {
    this.$store.commit('SET_HEADER_TEXT', this.$store.state.data.content.error_pagenotfound)
  },
  beforeUpdate() {
    this.$store.commit('SET_HEADER_TEXT', this.$store.state.data.content.error_pagenotfound)
  },
  beforeDestroy() {
    this.$store.commit('SET_HEADER_TEXT', '')
  },
}
</script>
