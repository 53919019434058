export const priceChartData = (labels) => ({
  type: 'bar',
  data: {
    labels,
    datasets: [],
  },
  options: {
    legend: {
      display: false,
    },
    responsive: true,
    maintainAspectRatio: false,
    lineTension: 1,
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
          ticks: {
            padding: 9,
            fontFamily: 'museo-sans',
            fontColor: '#485465',
            fontStyle: 300,
            fontSize: 10,
          },
        },
      ],
      yAxes: [
        {
          stacked: true,
          ticks: {
            beginAtZero: true,
            fontFamily: 'museo-sans',
            fontColor: '#485465',
            fontStyle: 300,
            fontSize: 10,
          },
        },
      ],
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem, data) {
          var label = data.datasets[tooltipItem.datasetIndex].label || ''

          if (label === '€') {
            const format = (n) =>
              Intl.NumberFormat('nl-NL', {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              }).format(n)
            const value = Number(tooltipItem.value)
            return `€${format(value)}`
          } else {
            const format = (n) => Intl.NumberFormat('nl-NL', { maximumFractionDigits: 1 }).format(n)
            const value = Number(tooltipItem.value)
            return `${format(value)} ${label}`
          }
        },
      },
      backgroundColor: '#ffffff',
      titleFontColor: '#000000',
      borderColor: '#000000',
      borderWidth: '1',
      bodyFontColor: '#000000',
      displayColors: false,
    },
  },
})
