import { replaceDynamicContent } from '@/app/filters/replaceDynamicContent'

/* 
Custom Plugin For Handling dynamic Content Parsing
(see: https://vuejs.org/v2/guide/custom-directive.html#Directive-Hook-Arguments)

Usage in templates in one of three ways:
1. v-dynamic-content:foo
 - for all cases without substitutions OR
   where default substitutions defined in ../functions/substitutions.js are applicable
2. v-dynamic-content:foo="[bar]"
 - parse content string with custom substitutions
3. v-dynamic-content="{ rawText: foo, substitutions: [bar, 'baz'] }"
 - provide custom inputstring with custom substitutions
*/

const setDynamicContent = (el, { arg, value }, vnode) => {
  const { content } = vnode.context.$store.getters
  let res
  if (arg && !value) {
    res = content[arg]
    if (!res) return
  } else if (arg && Array.isArray(value)) {
    if (!content[arg]) return
    res = replaceDynamicContent(content[arg], value)
  } else if (value.rawText) {
    res = replaceDynamicContent(value.rawText, value.substitutions)
  } else if (value.key) {
    res = replaceDynamicContent(content[value.key], value.substitutions)
  }
  el.innerHTML = res
}

export default {
  bind: setDynamicContent,
  update: setDynamicContent,
}
