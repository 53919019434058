<template>
  <div class="container">
    <div class="card">
      <div class="card-body">
        {{ errorText }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageError',
  computed: {
    errorText() {
      return this.$store.state.data.content.error_generic_body_text
    },
  },
  methods: {
    setHeaderText() {
      this.$store.commit(
        'SET_HEADER_TEXT',
        this.$store.state.data.content.error_generic_header_text
      )
    },
  },
  mounted() {
    this.setHeaderText()
  },
  beforeUpdate() {
    this.setHeaderText()
  },
  beforeDestroy() {
    this.$store.commit('SET_HEADER_TEXT', '')
    this.$store.commit('SET_ERROR', '')
  },
}
</script>
