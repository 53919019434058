<template>
  <div
    v-if="visible"
    ref="headerBarElement"
    class="header-sticky-navbar navbar navbar-expand-lg navbar-light bg-light fade-visible py-0"
  >
    <div class="container">
      <ul class="navbar-nav flex-row d-md-flex container-fluid align-items-center">
        <li class="col-4 d-flex align-content-start nav-logos align-items-center">
          <router-link to="/">
            <template>
              <img
                class="nav-logo-first"
                :src="require(`assets/icons/mysungevity/logo-sungevity-engie-${logoStyling}.svg`)"
              />
            </template>
          </router-link>
          <img
            class="nav-logo-split"
            :src="
              require(`assets/icons/mysungevity/logo-woco-${wocoLogo}-${logoStyling}.${wocoLogoFileType}`)
            "
            v-if="isB2B && this.wocoLogo"
          />
        </li>
        <li class="col nav-item align-items-end pr-0">
          <hamburger-menu></hamburger-menu>
        </li>
      </ul>
    </div>
  </div>
  <div v-else ref="headerBarElement"></div>
</template>

<script>
import Vue from 'vue'

import HamburgerMenu from './HamburgerMenu'
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderNavbar',
  components: {
    HamburgerMenu,
  },
  data() {
    return {
      position: -1,
      scope: this,
      scrollThreshold: 50,
      logoColor: 'white',
    }
  },
  computed: {
    ...mapGetters(['isB2B', 'companyCognitoGroupName']),
    visible() {
      // don't show nav on login
      const path = this.$route.path
      return !(path.indexOf(Vue.types.pages.LOGIN) > -1 || path.indexOf(Vue.types.pages.ADMIN) > -1)
    },
    logoStyling() {
      return this.logoColor
    },
    wocoLogoFileType() {
      // dealliantie has .png file for their white logo, but a color .svg
      if (this.wocoLogo === 'dealliantie' && this.logoColor === 'white') return 'png'

      const pngFiles = ['amvest', 'intermaris', 'trivire', 'parteon', 'vanalckmaer', 'wbvelsen']
      if (pngFiles.includes(this.wocoLogo)) return 'png'
      else return 'svg'
    },
    wocoLogo() {
      if (!this.companyCognitoGroupName) return
      return this.companyCognitoGroupName.substring(4)
    },
  },
  methods: {
    scrollDone() {
      const headerBar = this.scope.$refs.headerBarElement
      const topScrollPosition = window.scrollY
      if (topScrollPosition > 5) {
        // onscroll add onscroll styling
        headerBar.classList.add('onScrollNav')
        this.logoColor = 'color'
      } else {
        // default, add default styling
        headerBar.classList.remove('onScrollNav')
        this.logoColor = 'white'
      }
      // use a threshold for scrolling which prevents flickering and gives better behaviour.
      if (topScrollPosition <= Math.max(0, this.scope.position - this.scope.scrollThreshold)) {
        headerBar.classList.remove('fade-to-none')
      }
      // clear any timeout.
      clearTimeout(this.scope.scrollTimeout)
      this.scope.scrollTimeout = setTimeout(() => {
        const scrollPosition = window.scrollY
        if (headerBar) {
          // when scrollposition is down, add class to fade it away (when scrolled more than the thershold for smoother experience)
          if (
            scrollPosition > 50 &&
            scrollPosition > this.scope.position + this.scope.scrollThreshold
          ) {
            headerBar.classList.add('fade-to-none')
          }
        }
        this.scope.position = scrollPosition
      }, 100)
    },
  },
  mounted() {
    this.scope.position = window.scrollY
    if (this.$refs.headerBarElement) {
      window.addEventListener('scroll', () => {
        this.scrollDone()
      })
    }
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollDone)
  },
  updated() {
    this.scope.position = window.scrollY
    if (this.$refs.headerBarElement) {
      window.addEventListener('scroll', () => {
        this.scrollDone()
      })
    }
  },
}
</script>
