<template>
  <div class="container card-full-width-mobile mt-3 mt-md-9">
    <loader
      v-if="
        !this.$store.getters.profile ||
        !this.$store.getters.locale ||
        !this.$store.state.data.content
      "
    ></loader>
    <div v-else>
      <b-card tag="article" class="mb-2 referrals-whole-card">
        <div class="mx-lg-9 mx-md-6 pt-md-7 pt-4">
          <referral-welcome />
          <Form />
          <how-it-works />
          <gifts />
        </div>
      </b-card>
      <most-asked-questions />
    </div>
  </div>
</template>

<script>
import HowItWorks from '@/app/components/customer/referrals/referralPage/HowItWorks'
import Gifts from '@/app/components/customer/referrals/referralPage/Gifts'
import Form from '@/app/components/customer/referrals/referralPage/Form'
import ReferralWelcome from '@/app/components/customer/referrals/referralPage/ReferralWelcome'
import MostAskedQuestions from '@/app/components/customer/referrals/referralPage/MostAskedQuestions'
import Loader from '@/app/components/main/loader/Loader'

export default {
  name: 'ReferralPage',
  components: {
    HowItWorks,
    Gifts,
    Form,
    ReferralWelcome,
    MostAskedQuestions,
    Loader,
  },
}
</script>
