import dataModule from '@/app/store/modules/data'
import monitoringModule from '@/app/store/modules/monitoring'
import referralsModule from '@/app/store/modules/referrals'
import adminModule from '@/app/store/modules/admin'
import b2bModule from '@/app/store/modules/b2b'
import Vue from 'vue'
import Vuex from 'vuex'
import plugins from './plugins'

Vue.use(Vuex)

const modules = {
  data: dataModule,
  monitoring: monitoringModule,
  admin: adminModule,
  b2b: b2bModule,
}

if (dataModule.state.referralsEnabled) {
  modules.referrals = referralsModule
}

export default new Vuex.Store({
  modules,
  plugins: [plugins],
})
