import { Auth } from 'aws-amplify'

export default {
  methods: {
    async login(emailRaw, password) {
      const email = emailRaw.toLowerCase()
      this.loading = true

      try {
        const res = await Auth.signIn({ username: email, password })

        // forced password reset because of new account
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          this.loading = false
          this.$router.push({ name: 'changePassword', params: { email, password } })
        } else {
          this.$router.push({ path: '/' }).catch(() => {})
        }
      } catch (error) {
        // forced password reset because of admin manual reset
        if (error.code === 'PasswordResetRequiredException') {
          await Auth.forgotPassword(email)
          this.$router.push(`/login/passwordrequestsent`)
        } else {
          this.formErrorHandling(error)
          this.$store.commit('SET_EXTERNAL_QUERY', null)
          throw error
        }
      }
    },
  },
}
