<template>
  <div class="container pt-8 header-intro">
    <div class="row">
      <div class="col-12">
        <h2 v-if="headertext" class="font-weight-bold title text-white mt-8">{{ headertext }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderBanner',
}
</script>
