<template>
  <form-template>
    <template slot="side">
      <inlog-locale-switch></inlog-locale-switch>
      <h2 class="text-primary" v-dynamic-content:login_title_login></h2>
      <p v-dynamic-content:login_text_cta_login></p>
      <p v-dynamic-content:login_text_info></p>
    </template>

    <template slot="form">
      <b-alert variant="danger" :show="!!errorMsg">{{ errorMsg }}</b-alert>

      <form @submit.prevent="submitForm" novalidate>
        <b-form-group>
          <label class="mb-0" v-dynamic-content:login_field_email_text></label>
          <b-form-input
            type="email"
            @blur="$v.email.$touch()"
            v-model="email"
            autocomplete="email"
            :placeholder="content.login_field_email"
            :state="$v.email.$dirty ? !$v.email.$invalid : null"
            :disabled="loading"
          ></b-form-input>
          <b-form-invalid-feedback>
            <div v-if="!$v.email.emailValidation">{{ content.login_error_invalid_email }}</div>
            <div v-if="!$v.email.requiredValidation">{{ content.login_error_required }}</div>
          </b-form-invalid-feedback>
        </b-form-group>
        <div class="pb-6">
          <label class="mb-0" v-dynamic-content:login_field_password_new_text></label>
          <b-form-input
            type="password"
            @blur="$v.password.$touch()"
            v-model="password"
            :placeholder="content.login_field_password"
            :state="$v.password.$dirty ? !$v.password.$invalid : null"
            :disabled="loading"
          ></b-form-input>
          <b-form-invalid-feedback>
            <div
              v-if="!$v.password.requiredValidation"
              v-dynamic-content:login_error_required
            ></div>
          </b-form-invalid-feedback>
        </div>

        <b-button class="btn-block" type="submit" variant="primary" :disabled="loading">
          <span
            class="spinner-border spinner-border-sm mr-2"
            role="status"
            aria-hidden="true"
            v-if="loading"
          ></span>
          <span v-dynamic-content:login_button_login></span>
        </b-button>
        <router-link to="/login/forgotpassword">
          <p class="text-center mt-4" v-dynamic-content:login_forget></p>
        </router-link>
      </form>
    </template>
  </form-template>
</template>

<script>
import queryString from 'query-string'

import loginMixin from '@/app/mixins/login'
import FormMixin from '@/app/components/main/form/FormMixin'
import InlogLocaleSwitch from './parts/InlogLocaleSwitch'

export default {
  name: 'Login',
  mixins: [FormMixin, loginMixin],
  data() {
    return {
      email: '',
      password: '',
    }
  },
  components: {
    InlogLocaleSwitch,
  },
  methods: {
    async submitForm() {
      this.$v.$touch()
      if (this.$v.email.$invalid || this.$v.password.$invalid) {
        return
      }
      try {
        await this.login(this.email, this.password)
      } catch {
        this.$v.$reset()
      }
    },
    async checkActivationFlow() {
      const qs = queryString.parse(window.location.search)
      if (qs.email) {
        this.email = qs.email
        await this.login(qs.email, qs.token)
      }
    },
  },
  mounted() {
    this.checkActivationFlow()
  },
  beforeUpdate() {
    this.$store.commit('SET_HEADER_TEXT', null)
  },
  beforeDestroy() {
    this.$store.commit('SET_EXTERNAL_QUERY', null)
  },
}
</script>
