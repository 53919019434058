export default {
  computed: {
    profile() {
      return this.$store.getters.profile
    },
    logoLocale() {
      return {
        nl: 'netherlands',
        be: 'belgium',
      }[this.$store.state.data.countryCode]
    },
  },
}
