<template>
  <div class="mb-7">
    <h2
      class="text-md-left text-center text-primary mb-7 font-weight-normal"
      v-dynamic-content:referrals_how_it_works_title
    ></h2>
    <b-container class="d-md-block d-none">
      <b-row class="referrals-how-it-works-image-row">
        <b-col
          v-for="(howTo, index) in howItWorksList"
          :key="index"
          cols="12"
          md="4"
          align-v="start"
          class="text-center"
        >
          <img :src="howTo.image" class="mx-auto" />
        </b-col>
      </b-row>
      <b-row class="referrals-how-it-works-title-row">
        <b-col
          v-for="(howTo, index) in howItWorksList"
          :key="index"
          cols="12"
          md="4"
          align-v="start"
          class="text-center"
        >
          <h4
            class="my-6 font-weight-semibold text-primary"
            v-html="$options.filters.replaceDynamicContent(howTo.title)"
          ></h4>
        </b-col>
      </b-row>
      <b-row class="referrals-how-it-works-text-row">
        <b-col
          v-for="(howTo, index) in howItWorksList"
          :key="index"
          cols="12"
          md="4"
          align-v="start"
          class="text-center"
        >
          <p
            v-html="
              $options.filters.replaceDynamicContent(howTo.text, [
                $options.filters.formatCurrency(100),
              ])
            "
          ></p>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="d-md-none">
      <b-row class="referrals-how-it-works-row">
        <b-col
          v-for="(howTo, index) in howItWorksList"
          :key="index"
          cols="12"
          md="4"
          align-v="start"
          class="text-center"
        >
          <img :src="howTo.image" class="mx-auto" />
          <h4
            class="my-6 font-weight-semibold text-primary"
            v-html="$options.filters.replaceDynamicContent(howTo.title)"
          ></h4>
          <p
            v-html="
              $options.filters.replaceDynamicContent(howTo.text, [
                $options.filters.formatCurrency(100),
              ])
            "
          ></p>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import emailIcon from '@/assets/icons/deel-de-zon.svg'
import giftIcon from '@/assets/icons/cadeau.svg'
import planetIcon from '@/assets/icons/planet.svg'

export default {
  mixins: [],
  computed: {
    howItWorksList() {
      return [
        {
          title: this.content.referrals_email_your_network,
          text: this.content.referrals_email_your_network_text,
          image: emailIcon,
        },
        {
          title: this.content.referrals_choose_your_gift,
          text: this.content.referrals_choose_your_gift_text,
          image: giftIcon,
        },
        {
          title: this.content.referrals_sustainable_future,
          text: this.content.referrals_sustainable_future_text,
          image: planetIcon,
        },
      ]
    },
  },
}
</script>
