<template>
  <div class="d-flex align-items-center menu h-100" v-if="profile">
    <b-dropdown
      v-if="$mq !== 'xl'"
      right
      id="hamburger-menu"
      class="btn ml-auto dropdown-profile d-flex align-items-end mt-2"
    >
      <template slot="button-content">
        <div class="hamburger-menu-button" ref="toggleHamburgerMenu"></div>
      </template>

      <b-dropdown-header class="dropdownheader-mobile d-flex d-lg-none justify-content-between">
        <div class="d-flex align-content-start menu-logos nav-logos mt-1 ml-1">
          <router-link to="/">
            <img
              :src="require(`../../../../assets/icons/mysungevity/logo-sungevity-engie-color.svg`)"
            />
          </router-link>
        </div>
        <button
          type="button"
          class="close mr-4 hamburger-menu-button"
          aria-label="Close"
          @click="triggerToggle"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </b-dropdown-header>
      <b-dropdown-divider></b-dropdown-divider>
      <b-dropdown-header class="d-flex justify-content-center header-mobile-user">
        {{ profile.firstName }} {{ profile.lastName }}
      </b-dropdown-header>
      <div v-for="(item, index) of menuItems" :key="index" class="menu-items">
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item
          v-if="item.link === 'FaqPage'"
          :href="externalFaqLink"
          target="_blank"
          class="text-center"
        >
          {{ item.title }}
        </b-dropdown-item>
        <b-dropdown-item v-else class="text-center">
          <router-link :to="{ name: item.link }">{{ item.title }}</router-link>
        </b-dropdown-item>
      </div>
      <profile-menu></profile-menu>
    </b-dropdown>

    <div v-else class="d-flex align-items-end ml-auto h-100 align-items-center">
      <div v-for="(item, index) of menuItems" :key="index" class="h-100">
        <a
          v-if="item.link === 'FaqPage'"
          :href="externalFaqLink"
          target="_blank"
          @mouseover="externalActive = true"
          @mouseout="externalActive = false"
          class="px-2 menu-items h-100 d-flex align-items-center menu-items-large"
          :class="{ whiteBottomBorder: externalActive }"
        >
          {{ item.title }}
        </a>
        <router-link
          v-else
          @mouseover.native="active = true"
          @mouseout.native="active = false"
          class="px-2 menu-items h-100 d-flex align-items-center menu-items-large"
          :class="{
            whiteBottomBorder:
              activePage.startsWith(item.match || item.link) && !active && !externalActive,
          }"
          :to="{ name: item.link }"
        >
          {{ item.title }}
        </router-link>
      </div>
      <profile-menu class="ml-3"></profile-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ProfileMenu from './ProfileMenu'

export default {
  name: 'HamburgerMenu',
  components: {
    ProfileMenu,
  },
  data() {
    return {
      active: false,
      externalActive: false,
    }
  },
  computed: {
    ...mapGetters(['profile']),
    menuItems() {
      if (this.$store.getters.isB2B) {
        return [
          {
            title: this.content.b2b_dashboard_menu_title,
            link: 'B2BDashboard',
          },
          {
            title: this.content.b2b_locations_menu_title,
            link: 'B2BLocationList',
            match: 'B2BLocation',
          },
          {
            title: this.content.b2b_production_overview_menu_title,
            link: 'B2BProductionOverview',
          },
        ]
      } else {
        const navItems = []
        navItems.push({ title: this.content.monitoring_navitem_monitoring, link: 'Overview' })
        if (this.$store.getters.referralsEnabled) {
          navItems.push({ title: this.content.monitoring_navitem_sharesun, link: 'ReferralPage' })
        }
        navItems.push({ title: this.content.monitoring_navitem_faq, link: 'FaqPage' })
        return navItems
      }
    },
    activePage() {
      return this.$route.name || ''
    },
    externalFaqLink() {
      return `https://sungevity.freshdesk.com/${this.profile.locale.substring(0, 2)}/support/home`
    },
  },
  methods: {
    triggerToggle() {
      this.$refs.toggleHamburgerMenu.click()
    },
  },
}
</script>
