<template>
  <a @click="logout">
    <strong>{{ content.dropdown_logout }}</strong>
  </a>
</template>

<script>
import Vue from 'vue'
import { Auth } from 'aws-amplify'

export default {
  name: 'Logout',
  methods: {
    async logout() {
      if (this.$store.getters.isAdmin) {
        await this.$store.dispatch('adminSignOut')
        this.$router.push(Vue.types.pages.ADMIN)
      } else {
        await Auth.signOut()
        this.$store.commit('LOGOUT')
        this.$router.push(Vue.types.pages.LOGIN)
      }
    },
  },
}
</script>
