<template>
  <b-card>
    <div v-if="!this.$store.state.referrals.emailTemplate">
      <loader />
    </div>
    <div class="card-text body-color referrals-email-template" v-else>
      {{ template[0] }}
      <b-container class="p-0">
        <b-row align-h="start" class="p-0">
          <b-col cols="1">
            <hr />
          </b-col>
        </b-row>
      </b-container>
      {{ template[1] }}
    </div>
  </b-card>
</template>

<script>
export default {
  computed: {
    template() {
      const newTemplate = this.manipulateTemplate(
        this.$store.state.referrals.emailTemplate.data.text
      )
      return newTemplate
    },
  },
  methods: {
    manipulateTemplate(emailTemplate) {
      const regExOne = /({{)/g
      const regExTwo = /(}})/g
      const firstName = /<senderFirstname>/g
      const lastName = /<senderLastname>/g
      const replace = emailTemplate
        .replace(regExOne, '<')
        .replace(regExTwo, '>')
        .replace('<referralCode>', 'xxxx')
        .replace('<recieverName>', this.content.referrals_email_template_reciever_name)
        .replace('<personalizedText>', this.content.referrals_email_template_personalized_text)
        .replace(firstName, this.profile.firstName)
        .replace(lastName, this.profile.lastName)
        .replace('<senderEmail>', this.profile.email)
      const split = replace.split('--------')
      return split
    },
  },
}
</script>
