import Vue from 'vue'
import axios from 'axios'
import { router } from '@/app/router'
import { Auth } from 'aws-amplify'
import config from 'config'

const deleteAuthorizationHeader = (headerName) => {
  if (headerName) {
    delete Vue.$httpAuthorizedMonitoring.defaults.headers.common[headerName]
    delete Vue.$httpAuthorizedReferrals.defaults.headers.common[headerName]
    delete Vue.$httpAuthorizedMonitoringPlatform.defaults.headers.common[headerName]
  }
}

// subscribe on logged in: get profile
export default function plugins(store) {
  store.subscribe((mutation) => {
    switch (mutation.type) {
      case 'SET_AUTHENTICATED_USER_DATA':
        store.dispatch('getContentDefault')

        if (!mutation.payload) {
          deleteAuthorizationHeader('Authorization')

          // clear the sessionStorage after logout;
          sessionStorage.clear()
          localStorage.clear()
        }
        break
      case 'SET_LOCALE':
        store.dispatch('getContentDefault')
        break

      case 'SET_BASE_URL':
        {
          const baseMonitoring = axios.create({
            baseURL: store.getters.baseUrl['monitoring-api'],
          })

          let baseReferrals
          if (
            config['/sg/my-sungevity/referrals-enabled'] &&
            config['/sg/my-sungevity/referrals-enabled'] > 0
          ) {
            baseReferrals = axios.create({
              baseURL: store.getters.baseUrl['referrals-api'],
            })
          }

          const baseMonitoringPlatform = axios.create({
            baseURL: store.getters.baseUrl['monitoring-platform-api'],
          })

          // Add a response interceptor
          const interceptorUse = (base) =>
            base.interceptors.response.use(
              //skip default handling of response, just return
              (response) => response,
              (error) => {
                if (
                  error.response &&
                  (error.response.status === 401 || error.response.status === 403)
                ) {
                  if (store.getters.isAdmin) {
                    store.dispatch('adminSignOut')
                    router.push(Vue.types.pages.ADMIN)
                  } else {
                    store.dispatch('signOut')
                    router.push(Vue.types.pages.LOGIN)
                  }
                }
              }
            )

          const requestInterceptor = (base) =>
            base.interceptors.request.use(async (config) => {
              const session = await Auth.currentSession()
              if (session && session.idToken && session.idToken.jwtToken) {
                config.headers.common.authorization = session.idToken.jwtToken
              }
              return config
            })

          // use different base urls for interceptor
          requestInterceptor(baseMonitoringPlatform)
          requestInterceptor(baseMonitoring)
          if (baseReferrals) {
            requestInterceptor(baseReferrals)
          }
          interceptorUse(baseMonitoringPlatform)
          interceptorUse(baseMonitoring)
          if (baseReferrals) {
            interceptorUse(baseReferrals)
          }
          // initiate different names for calls
          if (baseReferrals) {
            interceptorUse(baseReferrals)
            Vue.$httpAuthorizedReferrals = baseReferrals
          }
          Vue.$httpAuthorizedMonitoring = baseMonitoring
          Vue.$httpAuthorizedMonitoringPlatform = baseMonitoringPlatform
        }
        break
      default:
        break
    }
  })
}
