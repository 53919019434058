<template>
  <div class="card">
    <h2 class="mb-7" v-dynamic-content:b2b_production_graph_title></h2>
    <b-spinner v-if="loading || updating"></b-spinner>

    <div :class="{ loading }">
      <p class="mb-3">
        <span v-dynamic-content:b2b_production_graph_total_production_all_systems></span>
        <span> {{ totalProductionDeltaForeverFormatted }}</span>
      </p>

      <hr />

      <div :class="{ loading: updating && !loading }">
        <location-deltas-graph-header
          :production="totalProductionDelta"
          :guarantee="totalProductionGuarantee"
        ></location-deltas-graph-header>
        <location-deltas-graph :deltas="deltas"></location-deltas-graph>
        <location-deltas-progress
          :progress="progress"
          :currentYear="currentYear"
        ></location-deltas-progress>

        <div class="container mb-4">
          <div class="row">
            <div class="col-12 col-md-auto mb-4 mb-md-0 px-0 px-md-4">
              <customer-reference-selector
                v-model="selectedCustomerReference"
                :customerReferences="customerReferences"
              >
              </customer-reference-selector>
            </div>
            <div class="col-12 col-md-auto px-0 px-md-4">
              <year-selector v-model="selectedYear" :onlineSince="onlineSince"> </year-selector>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapState } from 'vuex'
import YearSelector from '@/app/components/b2b/common/YearSelector'
import CustomerReferenceSelector from '@/app/components/b2b/common/CustomerReferenceSelector'
import LocationDeltasGraph from '@/app/components/b2b/common/LocationDeltasGraph'
import LocationDeltasProgress from '@/app/components/b2b/common/LocationDeltasProgress'
import LocationDeltasGraphHeader from '@/app/components/b2b/common/LocationDeltasGraphHeader'

export default {
  name: 'ProductionGraphBlock',
  components: {
    YearSelector,
    CustomerReferenceSelector,
    LocationDeltasGraph,
    LocationDeltasProgress,
    LocationDeltasGraphHeader,
  },
  data() {
    return {
      selectedYear: DateTime.local().year,
      selectedCustomerReference: null,
    }
  },
  computed: {
    ...mapState({
      loading: ({ b2b }) =>
        b2b.locationGroupDeltas === 'not_set' || b2b.locationGroup === 'not_set',
      currentYear: ({ b2b }) =>
        (b2b.locationGroupDeltasInput &&
          DateTime.fromISO(b2b.locationGroupDeltasInput.intervalStartAt).year) ||
        '...',
      onlineSince: ({ b2b }) => b2b.locationGroup.onlineSince,
      customerReferences: ({ b2b }) => b2b.locationGroup.customerReferences,
      locationGroupId: ({ b2b }) => b2b.locationGroup.id,
      deltas: ({ b2b }) => b2b.locationGroupDeltas.deltas,
      totalProductionGuarantee: ({ b2b }) => b2b.locationGroupDeltas.totalProductionGuarantee,
      totalProductionDelta: ({ b2b }) =>
        b2b.locationGroupDeltas.totalProductionDelta &&
        b2b.locationGroupDeltas.totalProductionDelta.kwhProduced,
      totalProductionDeltaForever: ({ b2b }) => b2b.locationGroup.totalProductionDelta.kwhProduced,
    }),
    totalProductionDeltaForeverFormatted() {
      if (this.loading) return '...'
      return this.$options.filters.formatKwh(this.totalProductionDeltaForever)
    },
    inputForFetchDeltas() {
      const start = DateTime.local(this.selectedYear, 1, 1)
      return {
        locationGroupId: this.locationGroupId,
        intervalStartAt: start.toISODate(),
        intervalEndAt: start.endOf('year').toISODate(),
        customerReference: this.selectedCustomerReference,
      }
    },
    updating() {
      const { b2b } = this.$store.state
      return (
        JSON.stringify(this.inputForFetchDeltas) !== JSON.stringify(b2b.locationGroupDeltasInput)
      )
    },
    progress() {
      if (this.loading) return '0%'

      const ratio =
        this.totalProductionDelta && this.totalProductionGuarantee
          ? this.totalProductionDelta / this.totalProductionGuarantee
          : 0
      return `${Math.round(ratio * 100)}%`
    },
  },
  methods: {
    fetchDeltas() {
      if (this.locationGroupId) {
        this.$store.dispatch('fetchLocationGroupDeltas', this.inputForFetchDeltas)
      }
    },
  },
  watch: {
    locationGroupId() {
      this.fetchDeltas()
    },
    selectedYear() {
      this.fetchDeltas()
    },
    selectedCustomerReference() {
      this.fetchDeltas()
    },
  },
  mounted() {
    this.fetchDeltas()
  },
}
</script>

<style scoped lang="scss">
div.card {
  padding: 24px;
}

h2 {
  text-align: center;
  color: $brand-color;
  font-size: 23px;
}

.spinner-border {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10em;
  z-index: 10;
  color: $brand-color;
}

.loading {
  opacity: 0.4;
}
</style>
