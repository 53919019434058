import Vue from 'vue'
import store from '@/app/store'

const formatNumberRaw = (value, options) => {
  if (!store.getters.locale) {
    return value
  }

  const numberFormatOptions = options || {}
  if (!numberFormatOptions.minimumFractionDigits) {
    numberFormatOptions.minimumFractionDigits = 0
  }
  if (!numberFormatOptions.maximumFractionDigits) {
    numberFormatOptions.maximumFractionDigits = 0
  }
  return new Intl.NumberFormat(store.getters.locale.split('_')[0], numberFormatOptions).format(
    value
  )
}

// format amount with kwh, Wp or percentage
Vue.filter('formatKwh', (value, maxFractionDigits, minFractionDigits) => {
  if (!isNaN(value)) {
    const amount = formatNumberRaw(value, {
      minimumFractionDigits: minFractionDigits,
      maximumFractionDigits: maxFractionDigits,
    })
    return `${amount} ${store.getters.content.general_kwh || 'kWh'}`
  }
  return `${value} ${store.getters.content.general_kwh || 'kWh'}`
})

// format value as currency with correct currency symbol.
Vue.filter('formatCurrency', (value, fractionDigits) => {
  return formatNumberRaw(value, {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  })
})

export function formatNumber(value) {
  return value === undefined ? '-' : formatNumberRaw(value)
}

Vue.filter('formatNumber', formatNumber)
