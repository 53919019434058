<template>
  <div class="card h-100">
    <h2 class="mb-7" v-dynamic-content:b2b_systems_online_block_header></h2>

    <b-spinner v-if="loading"></b-spinner>

    <div :class="{ faded: loading, row: true, 'justify-content-start': true }" style="height: 100%">
      <div class="col-12 mt-auto">
        <div class="graph">
          <div class="text" v-if="!loading">
            {{ percentageOnline }}%
            <br />
            {{ content.b2b_systems_online_graph_online }}
          </div>
          <svg>
            <circle cx="150" cy="150" r="130"></circle>
            <circle
              class="faded-bg"
              cx="150"
              cy="150"
              r="130"
              :stroke-dashoffset="ratioOnline * 818"
            ></circle>
            <circle
              class="faded"
              cx="150"
              cy="150"
              r="130"
              :stroke-dashoffset="ratioOnline * 818"
            ></circle>
          </svg>
        </div>
      </div>

      <div class="col-12 mt-auto mb-4" v-if="!loading">
        <div class="row mt-7">
          <div
            class="col"
            v-dynamic-content:b2b_systems_online_installed_systems="[locationGroup.locationCount]"
          ></div>
          <div class="col-auto">{{ totalMeteringSystems }}</div>
        </div>

        <div class="row align-items-center mt-2">
          <div class="col-1">
            <div class="square orange"></div>
          </div>
          <div class="col" v-dynamic-content:b2b_systems_online_online_systems></div>
          <div class="col-auto">{{ locationGroup.meteringSystemCountGroupedByStatus.online }}</div>
        </div>

        <div class="row align-items-center mt-2">
          <div class="col-1">
            <div class="square orange faded"></div>
          </div>
          <div class="col" v-dynamic-content:b2b_systems_online_offline_systems></div>
          <div class="col-auto">{{ locationGroup.meteringSystemCountGroupedByStatus.offline }}</div>
        </div>

        <div class="row align-items-center mt-2">
          <div class="col-1">
            <div class="square yellow"></div>
          </div>
          <div class="col" v-dynamic-content:b2b_systems_online_inactive_systems></div>
          <div class="col-auto">
            {{ locationGroup.meteringSystemCountGroupedByStatus.inactive }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters(['locationGroup']),
    loading() {
      return this.locationGroup === 'not_set'
    },
    percentageOnline() {
      return Math.round(this.ratioOnline * 100)
    },
    totalMeteringSystems() {
      const { online, offline, inactive } = this.locationGroup.meteringSystemCountGroupedByStatus
      return online + offline + inactive
    },
    ratioOnline() {
      if (this.loading) return 0
      const { online } = this.locationGroup.meteringSystemCountGroupedByStatus
      if (this.totalMeteringSystems === 0) return 0

      return online / this.totalMeteringSystems
    },
  },
}
//  :stroke-dashoffset="ratioOnline*409"
</script>

<style scoped lang="scss">
div.card {
  padding: 24px;
}
div {
  font-size: 14px;
}

.square {
  width: 1em;
  height: 1em;
  border-radius: 4.2px;
}

.orange {
  background-color: $brand-color;
}

.yellow {
  background-color: #fde68a;
}

.faded {
  opacity: 0.4;
}
h2 {
  text-align: center;
  color: $brand-color;
  font-size: 23px;
}
svg {
  width: 100%;
  height: 100%;
}
circle {
  stroke: $brand-color;
  stroke-width: 25px;
  fill: none;
}
circle.faded-bg {
  stroke: white;
}
circle.faded,
circle.faded-bg {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke-dasharray: 818;
}
.text {
  color: $brand-color;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 900;
  font-size: 1.3em;
}
.graph {
  margin: auto;
  width: 300px;
  height: 300px;
  position: relative;
}
div.faded {
  opacity: 0.4;
}
.spinner-border {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10em;
  z-index: 10;
  color: $brand-color;
}
</style>
