import { router, routerGuards } from '@/app/router'

import Monitoring from './pages/Monitoring'
import Faq from './pages/Faq'
import Overview from './pages/Overview'

router.addRoute({
  path: '/monitoring',
  name: 'monitoring',
  component: Monitoring,
  beforeEnter: routerGuards.ifAuthenticated,
  redirect: 'monitoring/overview',
})
router.addRoute('monitoring', {
  path: 'overview',
  name: 'Overview',
  component: Overview,
  beforeEnter: routerGuards.ifAuthenticated,
})
router.addRoute('monitoring', {
  path: 'faq',
  name: 'FaqPage',
  component: Faq,
  beforeEnter: routerGuards.ifAuthenticated,
})
