<template>
  <section class="mb-5">
    <div class="canvas-section ml-0 ml-md-6 mt-5" :class="{ loading }">
      <canvas class="mb-md-5" ref="productionChart" id="production-chart"></canvas>
    </div>
    <div class="y-axis d-none d-md-flex">{{ yLabel }}</div>
    <scroll-date-buttons :value="page" v-if="chartTooBig"></scroll-date-buttons>
    <div class="w-100 text-center font-size-sm">{{ xLabel }}</div>
  </section>
</template>

<script>
import Chart from 'chart.js'
import { priceChartData } from './chart-data'
import { mapState, mapGetters } from 'vuex'
import ScrollDateButtons from './ScrollDateButtons.vue'
import { DateTime } from 'luxon'

/*
This component is now limited where possible to determining
how the chart should be rendered visually, given some input data.

Computing/preparing that data has been moved to the store.

The chartData is only recomputed when new data comes in from
the api (so not already when the user selects a new month).
This ensures the graph does not update before there is any
data to show.
*/

const YELLOW = '#ffdd00'

const categoryMapping = {
  electricityProduction: {
    color: YELLOW,
    contentKey: 'monitoring_production_graph_key_category_produced',
  },
}

export default {
  components: { ScrollDateButtons },
  data() {
    return {
      chart: null,
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'visibleDeltas',
      'displayAsPrice',
      'electricityProduction',
      'language',
      'barSettings',
    ]),
    ...mapState({
      loading: ({ monitoring }) => monitoring.loading.getDeltas,
      deltas: ({ monitoring }) => monitoring.deltas,
      selectedInterval: ({ monitoring }) => monitoring.inputs.interval,
      selectedTariff: ({ monitoring }) => monitoring.inputs.tariff,
      selectedIntervalStartAt: ({ monitoring }) =>
        DateTime.fromISO(monitoring.inputs.intervalStartAt),
      page: ({ monitoring }) => monitoring.inputs.page,
    }),
    chartTooBig() {
      return this.isMobile && (this.selectedInterval === 'day' || this.selectedInterval === 'month')
    },
    datasets() {
      return {
        electricityProduction: this.takeSlice(this.electricityProduction.map(this.toDisplayValue)),
      }
    },
    chartData() {
      if (this.loading) return []

      const deltas = this.visibleDeltas.map((k) => ({
        data: this.datasets[k],
        backgroundColor: this.datasets[k].map(() => categoryMapping[k].color),
        label: this.yLabel,
      }))

      return deltas
    },
    xAxisLabels() {
      const start = this.selectedIntervalStartAt
      const end = start.plus({ [this.selectedInterval]: 1 })
      const { step, format } = this.barSettings

      return this.takeSlice([...this.getRangeItems({ start, end, step, format })])
    },
    xLabel() {
      return {
        year: this.content.monitoring_production_graph_xaxis_label_months,
        day: this.content.monitoring_production_graph_xaxis_label_hours,
        week: this.content.monitoring_production_graph_xaxis_label_days,
        month: this.content.monitoring_production_graph_xaxis_label_days,
      }[this.selectedInterval]
    },
    yLabel() {
      if (this.displayAsPrice) return this.content.monitoring_production_graph_units_euro
      return this.content.monitoring_production_graph_units_kwh
    },
    getRangeItems() {
      return function* ({ start, end, step, format }) {
        let lowerBound = start.startOf(step)
        while (lowerBound < end) {
          yield lowerBound.setLocale(this.language).toFormat(format)
          lowerBound = lowerBound.plus({ [step]: 1 })
        }
      }
    },
  },
  watch: {
    chartData() {
      this.updateChart()
    },
  },
  mounted() {
    this.updateChart()
  },
  methods: {
    toDisplayValue(v) {
      const round = (n) => Math.round((n + Number.EPSILON) * 100) / 100
      return this.displayAsPrice ? round(v * this.selectedTariff) : v
    },
    // For slicing up the chart for mobile view
    // slices data in 3 parts for mobile view
    // and 1 part for !mobile
    takeSlice(xs) {
      const page = this.page
      const of = this.chartTooBig ? 3 : 1
      const start = page * Math.ceil(xs.length / of)
      const end = (page + 1) * Math.ceil(xs.length / of)
      return xs.slice(start, end)
    },
    updateChart() {
      if (!this.chart) {
        const init = priceChartData(this.xAxisLabels)
        this.chart = new Chart(this.$refs.productionChart, init)
      }
      this.chart.data.datasets = this.chartData
      this.chart.data.labels = this.xAxisLabels
      this.chart.update()
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  opacity: 0.3;
}
</style>
