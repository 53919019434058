import Vue from 'vue'

Vue.types = {
  pages: {
    ERROR: '/error',
    NOTFOUND: '/notfound',
    LOGIN: '/login',
    PROFILE: '/profile',
    ADMIN: '/admin',
  },
}
