<template>
  <div class="content-card col card col-12 col-md-4 align-items-center d-flex" style="width: 18rem">
    <div :class="{ image: true, circleImg: circleImage }" class="align-self-center">
      <img :src="showImage(imgSrc)" />
    </div>
    <div v-if="content" class="w-100 content-card-text">
      <div class="card-body text-center mt-8">
        <h4
          class="card-title mb-3"
          v-html="$options.filters.replaceDynamicContent(content[title])"
        ></h4>
        <p class="card-text" v-html="$options.filters.replaceDynamicContent(content[text])"></p>
        <router-link
          :to="{ name: linkTo }"
          class="d-flex justify-content-center align-items-center font-weight-bold"
          v-if="link"
        >
          <span>{{ content[link] }}<img class="ml-2" src="@/assets/icons/forward.svg" /></span>
        </router-link>
      </div>
    </div>
    <div v-else>
      <loader></loader>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'text', 'link', 'imgSrc', 'circleImage', 'linkTo'],
  methods: {
    showImage(image) {
      return require('@/assets/' + image)
    },
  },
}
</script>
