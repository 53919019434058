<template>
  <div v-if="profile">
    <router-view></router-view>
  </div>
  <div v-else>
    <loader></loader>
  </div>
</template>

<script>
export default {
  name: 'referrals',
}
</script>
