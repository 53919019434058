<template>
  <div class="card">
    <b-spinner v-if="fetching"></b-spinner>
    <h2 class="mb-7" v-dynamic-content:b2b_environmental_block_header></h2>

    <div :class="{ faded40: fetching }">
      <p v-dynamic-content:b2b_environmental_block_total_co2_savings="[totalCo2KgSaved]"></p>

      <hr />

      <b-spinner v-if="updating"></b-spinner>

      <div :class="{ faded: updating }">
        <h5 v-dynamic-content:b2b_environmental_block_per_year_header></h5>

        <p
          v-dynamic-content:b2b_environmental_block_yearly_savings_description="[
            environmentalImpactYear,
          ]"
        ></p>

        <div class="row justify-content-center mt-6">
          <img
            class="col px-0 d-none d-md-block mb-6"
            src="@/assets/icons/milieubijdrage-line-art.svg"
          />
        </div>
        <div class="row mb-6">
          <div class="col-12 col-md-4 text-center mb-8 mb-md-4">
            <img
              class="d-inline-block d-md-none mb-3"
              style="width: 135px"
              src="@/assets/icons/house-illustration.svg"
            />
            <div class="environment-discription w-100 font-weight-bold text-primary">
              {{ impact.co2KgSaved | formatNumber }} kg
              <a class="info-symbol ml-1" role="button" data-info-popup="co2CalculationPopup"></a>
            </div>
            <h4
              class="font-size-bold px-9 px-md-0 mb-3"
              v-dynamic-content:monitoring_environmental_gains_reducedco2="[]"
            ></h4>
          </div>
          <div class="col-12 col-md-4 text-center mb-8 mb-md-4">
            <img
              class="d-inline-block d-md-none mb-3"
              style="width: 135px"
              src="@/assets/icons/car-illustration.svg"
            />
            <div class="environment-discription w-100 font-weight-bold text-primary">
              {{ impact.carKmSaved | formatNumber }} km
              <a class="info-symbol ml-1" role="button" data-info-popup="kmsCalculationPopup"></a>
            </div>
            <h4
              class="font-size-bold px-9 px-md-0 mb-3"
              v-dynamic-content:monitoring_environmental_gains_reducedkm
            ></h4>
          </div>
          <div class="col-12 col-md-4 text-center mb-4">
            <img
              class="d-inline-block d-md-none mb-3"
              style="width: 135px"
              src="@/assets/icons/trees-illustration.svg"
            />
            <div class="environment-discription w-100 font-weight-bold text-primary">
              {{ impact.treesSaved | formatNumber }}
              <a class="info-symbol ml-1" role="button" data-info-popup="treesCalculationPopup"></a>
            </div>
            <h4
              class="font-size-bold px-9 px-md-0 mb-3"
              v-dynamic-content:monitoring_environmental_gains_trees
            ></h4>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-auto">
            <customer-reference-selector
              v-model="selectedCustomerReference"
              :customerReferences="customerReferences"
            ></customer-reference-selector>
          </div>
          <div class="col-12 col-md-auto">
            <year-selector v-model="selectedYear" :onlineSince="onlineSince"></year-selector>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DateTime } from 'luxon'
import { mapState } from 'vuex'
import { formatNumber } from '@/app/filters/formatNumbers'
import CustomerReferenceSelector from '@/app/components/b2b/common/CustomerReferenceSelector'
import YearSelector from '@/app/components/b2b/common/YearSelector'

const currentYear = DateTime.local().year

export default {
  components: {
    CustomerReferenceSelector,
    YearSelector,
  },
  data() {
    return {
      selectedYear: currentYear,
      selectedCustomerReference: null,
    }
  },
  computed: {
    ...mapState({
      impact: ({ b2b }) => b2b.locationGroup.environmentalImpact || {},
      fetching: ({ b2b }) => b2b.locationGroup === 'not_set',
      environmentalImpactYear: ({ b2b }) => b2b.environmentalImpactYear,
      environmentalImpactCustomerReference: ({ b2b }) => b2b.environmentalImpactCustomerReference,
      totalCo2KgSaved: ({ b2b }) => formatNumber(b2b.locationGroup.totalCo2KgSaved),
      customerReferences: ({ b2b }) => b2b.locationGroup.customerReferences,
      onlineSince: ({ b2b }) => b2b.locationGroup.onlineSince,
    }),
    updating() {
      const isNotSameYear = this.selectedYear !== this.environmentalImpactYear
      const isNotSameCustomerReference =
        this.selectedCustomerReference !== this.environmentalImpactCustomerReference
      return (isNotSameYear || isNotSameCustomerReference) && !this.fetching
    },
  },
  methods: {
    fetchEnvironmentalImpactData() {
      this.$store.dispatch('fetchEnvironmentalImpactData', {
        year: this.selectedYear,
        customerReference: this.selectedCustomerReference,
      })
    },
  },
  watch: {
    selectedYear() {
      this.fetchEnvironmentalImpactData()
    },
    selectedCustomerReference() {
      this.fetchEnvironmentalImpactData()
    },
  },
}
</script>

<style lang="scss" scoped>
.spinner-border {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10em;
  z-index: 10;
  color: $brand-color;
}
div.card {
  padding: 32px;
}
h2 {
  text-align: center;
  color: $brand-color;
  font-size: 23px;
}
.faded {
  opacity: 0.2;
}
.faded40 {
  opacity: 0.4;
}

.info-symbol {
  content: '';
  display: inline-block;
  background: url('../../../../assets/icons/info-symbol.svg') no-repeat;
  width: 18px;
  height: 18px;
  margin: 0;

  &:hover {
    background: url('../../../../assets/icons/info-symbol-hover.svg') no-repeat;
    background-size: 18px;
  }
}
</style>
