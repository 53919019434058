<template>
  <div v-if="content.referrals_gifts_overview_title">
    <h2 class="text-primary mb-6" v-dynamic-content:referrals_gifts_overview_title></h2>
    <div>
      <b-container class="p-0 mb-6">
        <b-row>
          <b-col cols="12">
            <p v-dynamic-content:referrals_gifts_overview_text></p>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-container class="p-0 mb-6">
      <b-row>
        <b-col v-for="(gift, index) in giftList" :key="index" cols="12" md="4" class="mt-4 mt-md-0">
          <a class="referrals-gift-card-links" :href="gift.link" target="_blank">
            <b-card
              :img-src="gift.image"
              :img-alt="gift.imageDescription"
              img-top
              tag="article"
              style="max-width: 20rem"
              class="mb-2 referrals-gift-cards font-weight-bold mx-auto h-100"
              text-overflow="ellipsis"
            >
              <p>{{ gift.title }}</p>
            </b-card>
          </a>
        </b-col>
      </b-row>
    </b-container>
    <div class="mb-3">
      <span class="font-weight-bold">
        <a :href="overViewGiftLink" target="_blank">
          <span v-dynamic-content:referrals_gifts_list_link></span>
          <img class="ml-2" src="@/assets/icons/forward.svg" />
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import giftOneBeligum from '@/assets/images/gift1-be.jpg'
import giftTwoBelgium from '@/assets/images/gift2-hd-be.jpg'
import giftThreeBelgium from '@/assets/images/gift3-hd-be.jpg'
import giftOneNetherlands from '@/assets/images/gift1-nl.jpg'
import giftTwoNetherlands from '@/assets/images/gift2-nl.jpg'
import giftThreeNetherlands from '@/assets/images/gift3-nl.jpg'

export default {
  data() {
    return {
      lang: this.$store.state.referrals.urlLang,
      giftLinks: {
        nl_BE: [
          'https://www.50five.be/nl/klimaatbeheersing/verwarming.html',
          'https://www.50five.be/nl/verlichting/philips-hue-white-ambiance-e27-starter-kit-met-dimmer.html',
          'https://www.50five.be/nl/ring-video-doorbell-satin-nickel.html',
        ],
        fr_BE: [
          'https://www.50five.be/fr/controle-du-climat/chauffage.html',
          'https://www.50five.be/fr/kit-de-depart-white-ambiance-philips-hue-e27-avec-variateur.html',
          'https://www.50five.be/fr/sonnette-video-ring-satin-nickel.html',
        ],
        nl_NL: [
          'https://www.sungevity.nl/cadeau',
          'https://www.sungevity.nl/cadeau',
          'https://www.sungevity.nl/cadeau',
        ],
      },
    }
  },
  methods: {
    getPicture(gift) {
      const picture = {
        gift1belgium: giftOneBeligum,
        gift2belgium: giftTwoBelgium,
        gift3belgium: giftThreeBelgium,
        gift1netherlands: giftOneNetherlands,
        gift2netherlands: giftTwoNetherlands,
        gift3netherlands: giftThreeNetherlands,
      }[gift]
      return picture
    },
  },
  computed: {
    giftList() {
      return [
        {
          title: this.content.referrals_gifts_one,
          image: this.getPicture(`gift1${this.logoLocale}`),
          link: this.giftLinks[this.$store.getters.locale][0],
        },
        {
          title: this.content.referrals_gifts_two,
          image: this.getPicture(`gift2${this.logoLocale}`),
          link: this.giftLinks[this.$store.getters.locale][1],
        },
        {
          title: this.content.referrals_gifts_three,
          image: this.getPicture(`gift3${this.logoLocale}`),
          link: this.giftLinks[this.$store.getters.locale][2],
        },
      ]
    },
    overViewGiftLink() {
      const link = {
        nl_NL: 'https://www.sungevity.nl/cadeau',
        nl_BE: 'https://www.50five.be/nl',
        fr_BE: 'https://www.50five.be/fr',
      }
      return link[this.$store.getters.locale]
    },
  },
}
</script>
