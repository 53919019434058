<template>
  <div class="container-fluid">
    <div
      class="content-low row align-items-start justify-content-between mx-sm-1 mx-md-0 pb-4 pb-md-8 mt-8 mt-md-10"
      v-if="content"
    >
      <FAQ
        :pagelink="{ text: content.monitoring_faq_subtext_link, link: '/monitoring/faq' }"
        page="referrals-faqs"
      />
      <ContentCard
        :title="'referrals_monitoring_card_title'"
        :text="'referrals_monitoring_card_text'"
        :link="'referrals_monitoring_card_link'"
        :imgSrc="'icons/monitoring.svg'"
        :linkTo="'Overview'"
        :circleImage="'true'"
        class="mt-7 mt-md-3 mb-sm-3"
      />
      <BacktoTopButton></BacktoTopButton>
    </div>
    <div v-else>
      <loader />
    </div>
  </div>
</template>

<script>
import Loader from '@/app/components/main/loader/Loader'
import FAQ from '@/app/components/main/faq/FAQ'
import ContentCard from '@/app/components/main/content/ContentCard'
import BacktoTopButton from '@/app/components/main/scroll/BacktoTopButton'

export default {
  data() {
    return {
      viewQuestionOne: false,
      viewQuestionTwo: false,
    }
  },
  methods: {
    toggleQuestionOne() {
      this.viewQuestionOne = !this.viewQuestionOne
    },
    toggleQuestionTwo() {
      this.viewQuestionTwo = !this.viewQuestionTwo
    },
  },
  components: {
    FAQ,
    Loader,
    ContentCard,
    BacktoTopButton,
  },
}
</script>
