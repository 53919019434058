<template>
  <div class="d-flex">
    <div :class="`${b2bMeteringSystemStatus}-icon`"></div>
    <div v-if="b2bMeteringSystemStatus === 'inactive'">{{ inactiveMeteringSystemType }}</div>
    <div v-else>{{ b2bMeteringSystemStatus }}</div>
  </div>
</template>

<script>
import { toB2BMeteringSystemStatus } from './utils'

export default {
  props: ['meteringSystem'],
  methods: {
    toB2BMeteringSystemStatus,
  },
  computed: {
    b2bMeteringSystemStatus() {
      return this.toB2BMeteringSystemStatus(this.meteringSystem)
    },
    inactiveMeteringSystemType() {
      return {
        replaced: 'meter vervangen',
        forbidden: 'geen medewerking eigenaar',
        unreachable: 'eigenaar onbereikbaar',
      }[this.meteringSystem.inactive.type]
    },
  },
}
</script>

<style scoped lang="scss">
.inactive-icon,
.offline-icon,
.online-icon {
  width: 0.5em;
  height: 0.5em;
  margin: 0.5em 0.5em 0.5em 0;
  border-radius: 0.5em;
}
.offline-icon {
  background-color: #ff6f5b;
}
.online-icon {
  background-color: #78d383;
}
.inactive-icon {
  background-color: #fde68a;
}
</style>
