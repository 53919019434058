<template>
  <form-template>
    <template slot="side">
      <inlog-locale-switch></inlog-locale-switch>
      <h2 class="text-primary" v-dynamic-content:create_new_password_title></h2>
      <password-rules></password-rules>
    </template>

    <template slot="form">
      <b-alert variant="danger" :show="!!errorMsg">{{ errorMsg }}</b-alert>
      <form @submit.prevent="confirmNewPassword" novalidate>
        <div v-if="!step2Active">
          <div class="pb-6">
            <label class="mb-0" v-dynamic-content:login_field_email_text></label>
            <b-form-input
              type="email"
              v-model="email"
              autocomplete="email"
              :placeholder="content.login_field_email"
              :state="$v.email.$dirty ? !$v.email.$invalid : null"
              :disabled="loading"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div v-if="!$v.email.emailValidation">{{ content.login_error_invalid_email }}</div>
              <div v-if="!$v.email.requiredValidation">{{ content.login_error_required }}</div>
            </b-form-invalid-feedback>
          </div>
          <div class="pb-6">
            <label class="mb-0" v-dynamic-content:login_field_verificationcode_text></label>
            <b-form-input
              type="text"
              maxlength="6"
              v-model="verificationCode"
              :placeholder="content.login_field_verificationcode"
              :state="$v.verificationCode.$dirty ? !$v.verificationCode.$invalid : null"
              :disabled="loading"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div v-if="!$v.verificationCode.requiredValidation">
                {{ content.login_error_required }}
              </div>
              <div v-if="!$v.verificationCode.codemismatchValidation">
                {{ content.login_error_codemismatch }}
              </div>
            </b-form-invalid-feedback>
          </div>
          <b-button class="btn-block" type="button" @click="toNextStep()" variant="primary">
            {{ content.login_button_login }}
          </b-button>
        </div>

        <div v-if="step2Active">
          <div class="pb-6">
            <p v-dynamic-content:login_confirm_password_intro_text></p>
            <label class="mb-0" v-dynamic-content:login_confirm_password_email_label></label>
            <b-form-input type="email" :value="email" disabled></b-form-input>
          </div>

          <div class="pb-6">
            <label class="mb-0" v-dynamic-content:login_field_password_new_text></label>
            <b-form-input
              type="password"
              name="password"
              v-model="newPassword"
              :placeholder="content.login_field_password_new"
              :state="$v.newPassword.$dirty ? !$v.newPassword.$invalid : null"
              :disabled="loading"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div v-if="!$v.newPassword.requiredValidation">
                {{ content.login_error_required }}
              </div>
              <div
                v-if="
                  $v.newPassword.requiredValidation && !$v.newPassword.passwordStrengthValidation
                "
              >
                {{ content.login_error_password_invalid }}
              </div>
            </b-form-invalid-feedback>
          </div>
          <div class="pb-6">
            <label class="mb-0" v-dynamic-content:login_field_password_new_confirm_text></label>
            <b-form-input
              type="password"
              v-model="newPassword2"
              :placeholder="content.login_field_password_new_confirm"
              :state="$v.newPassword2.$dirty ? !$v.newPassword2.$invalid : null"
              :disabled="loading"
            ></b-form-input>
            <b-form-invalid-feedback>
              <div v-if="!$v.newPassword2.requiredValidation">
                {{ content.login_error_required }}
              </div>
              <div v-if="$v.newPassword2.requiredValidation && !$v.newPassword2.sameAsValidation">
                {{ content.login_error_passwordnomatch }}
              </div>
            </b-form-invalid-feedback>
          </div>
          <b-button class="btn-block" type="submit" variant="primary" :disabled="loading">
            <span
              class="spinner-border spinner-border-sm mr-2"
              role="status"
              aria-hidden="true"
              v-if="loading"
            ></span>
            <span v-dynamic-content:login_field_password_new_confirm></span>
          </b-button>
          <a
            class="blue-link mt-4 d-flex justify-content-center align-items-center"
            @click="toPreviousStep()"
            v-dynamic-content:login_backtologin
          ></a>
        </div>
      </form>
    </template>
  </form-template>
</template>

<script>
import { Auth } from 'aws-amplify'

import loginMixin from '@/app/mixins/login'
import FormMixin from '@/app/components/main/form/FormMixin'
import InlogLocaleSwitch from './parts/InlogLocaleSwitch'
import PasswordRules from './parts/PasswordRules'

export default {
  name: 'confirmNewPassword',
  components: {
    InlogLocaleSwitch,
    PasswordRules,
  },
  mixins: [FormMixin, loginMixin],
  data() {
    return {
      loading: null,
      email: this.getExternalQuery('email') || null,
      verificationCode: this.getExternalQuery('code') || null,
      newPassword: null,
      newPassword2: null,
      step2Active: false,
    }
  },
  methods: {
    toNextStep() {
      this.$v.email.$touch()
      this.$v.verificationCode.$touch()
      if (this.$v.email.$invalid || this.$v.verificationCode.$invalid) {
        return
      }
      this.step2Active = true
    },
    getExternalQuery(param) {
      if (this.$store.getters.externalQuery[param]) {
        return this.$store.getters.externalQuery[param]
      }
      return null
    },
    toPreviousStep() {
      this.step2Active = false
    },
    async confirmNewPassword() {
      const email = this.email.toLowerCase()
      const { verificationCode, newPassword } = this

      this.$v.$touch()
      if (
        this.$v.email.$invalid ||
        this.$v.verificationCode.$invalid ||
        this.$v.newPassword.$invalid ||
        this.$v.newPassword2.$invalid
      ) {
        return
      }

      this.loading = true
      try {
        await Auth.forgotPasswordSubmit(email, verificationCode, newPassword)
        await this.login(this.email, newPassword)
      } catch (error) {
        this.loading = false
        this.formErrorHandling(error)
      }
    },
  },
  mounted() {
    if (this.$store.getters.externalQuery.email && this.$store.getters.externalQuery.code) {
      this.toNextStep()
    }
  },
  beforeUpdate() {
    this.$store.commit('SET_HEADER_TEXT', null)
  },
}
</script>
