<template>
  <div class="mb-7">
    <submission-banner
      :display="displayBanner"
      :resetBanner="resetBanner"
      v-if="this.displayBanner"
    ></submission-banner>
    <b-form @submit.prevent="onSubmit">
      <b-container class="p-0">
        <b-row v-for="(v, index) in $v.referees.$each.$iter" :key="index">
          <b-col cols="12" class=".d-sm-flex d-md-none mb-md-2 justify-content-end">
            <add-remove-fields
              @add="addEmail"
              @remove="removeEmail"
              :index="index"
              :showSmall="true"
            ></add-remove-fields>
          </b-col>
          <b-col cols="12" md="6" lg="4" class="referrals-form-ref-fields">
            <b-form-group
              class="form-group"
              label-size="sm"
              :label="content.referrals_form_first_name"
              :class="{ hide: !referees[index].firstName }"
              :ref="`firstName${index}`"
            >
              <b-form-input
                type="text"
                v-model.trim.lazy="v.firstName.$model"
                :placeholder="content.referrals_form_first_name"
                @oninput="resetBanner"
                :state="!v.firstName.required && v.firstName.$dirty ? !v.firstName.$invalid : null"
                @focus="removeOrAddHide(`firstName${index}`, index, 'remove', 'firstName')"
                @blur="removeOrAddHide(`firstName${index}`, index, 'add', 'firstName')"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                <div v-if="!v.firstName.$required">
                  {{ content.referrals_form_firstname_error }}
                </div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6" lg="4" class="referrals-form-ref-fields">
            <b-form-group
              class="form-group"
              label-size="sm"
              :label="content.referrals_form_last_name"
              :class="{ hide: !referees[index].lastName }"
              :ref="`lastName${index}`"
            >
              <b-form-input
                type="text"
                v-model.trim.lazy="v.lastName.$model"
                :placeholder="content.referrals_form_last_name"
                @oninput="resetBanner"
                :state="!v.lastName.required && v.lastName.$dirty ? !v.lastName.$invalid : null"
                @focus="removeOrAddHide(`lastName${index}`, index, 'remove', 'lastName')"
                @blur="removeOrAddHide(`lastName${index}`, index, 'add', 'lastName')"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                <div v-if="!v.lastName.$required">{{ content.referrals_form_lastname_error }}</div>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col cols="12" lg="4" class="referrals-form-ref-fields">
            <b-form-group
              class="form-group"
              label-size="sm"
              label-color="#a6bbc3"
              :label="content.referrals_form_email"
              :class="{ hide: !referees[index].email }"
              :ref="`email${index}`"
            >
              <b-form-input
                type="email"
                v-model.trim.lazy="v.email.$model"
                :placeholder="content.referrals_form_email"
                @oninput="resetBanner"
                :state="
                  (!v.email.required || !v.email.emailValidation) && v.email.$dirty
                    ? !v.email.$invalid
                    : null
                "
                @focus="removeOrAddHide(`email${index}`, index, 'remove', 'email')"
                @blur="removeOrAddHide(`email${index}`, index, 'add', 'email')"
              >
              </b-form-input>
              <b-form-invalid-feedback>
                <div v-if="v.email.$invalid">{{ content.referrals_form_email_error }}</div>
              </b-form-invalid-feedback>
              <add-remove-fields
                @add="addEmail"
                @remove="removeEmail"
                v-bind:index="index"
                :showSmall="false"
              ></add-remove-fields>
            </b-form-group>
          </b-col>
        </b-row>
      </b-container>
      <b-form-group label-size="sm" :label="content.referrals_form_personal_message" class="mb-7">
        <b-form-textarea
          type="text"
          v-model="personalMessage"
          :placeholder="content.referrals_form_personal_message_placeholder"
          rows="3"
          max-rows="5"
          @oninput="resetBanner"
        >
        </b-form-textarea>
      </b-form-group>
      <FormSeeTemplateAndSubmit :pending="pending" :referees="referees"></FormSeeTemplateAndSubmit>
      <b-collapse id="collapse1">
        <email-template />
      </b-collapse>
    </b-form>
  </div>
</template>

<script>
import { required, email as emailValidation } from 'vuelidate/lib/validators'
import SubmissionBanner from '@/app/components/customer/referrals/parts/SubmissionBanner'
import EmailTemplate from '@/app/components/customer/referrals/parts/EmailTemplate'
import AddRemoveFields from '@/app/components/customer/referrals/parts/AddRemoveFields'
import FormSeeTemplateAndSubmit from '@/app/components/customer/referrals/parts/FormSeeTemplateAndSubmit'

export default {
  data() {
    return {
      personalMessage: '',
      pending: false,
      referees: [
        {
          firstName: '',
          lastName: '',
          email: '',
        },
      ],
      displayBanner: '',
    }
  },

  components: {
    SubmissionBanner,
    EmailTemplate,
    AddRemoveFields,
    FormSeeTemplateAndSubmit,
  },

  methods: {
    async onSubmit() {
      const totalReferees = this.referees.length
      if (!this.referees[0].firstName) {
        this.displayBanner = 'danger'
        return
      }
      const lastReferee = this.referees[totalReferees - 1]
      if (
        totalReferees >= 1 &&
        !lastReferee.firstName &&
        !lastReferee.lastName &&
        !lastReferee.email
      ) {
        this.referees.pop()
      }
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.displayBanner = 'danger'
        return
      }
      this.pending = true

      const userSubmit = {
        referees: this.referees,
        personalizedText: this.personalMessage,
      }

      try {
        await this.$store.dispatch('sendReferrals', userSubmit)
        if (this.$store.state.referrals.emailSent) {
          this.displayBanner = 'success'
          this.referees = [
            {
              firstName: '',
              lastName: '',
              email: '',
            },
          ]
          this.personalMessage = ''
          this.pending = false
          setTimeout(() => {
            this.$v.$reset()
          }, 0)
        } else {
          this.displayBanner = 'danger'
          this.pending = false
        }
      } catch (error) {
        this.pending = false
      }
      this.referees = [
        {
          firstName: '',
          lastName: '',
          email: '',
        },
      ]
      this.$store.dispatch('resetEmailSent')
    },

    addEmail() {
      this.referees.push({
        firstName: '',
        lastName: '',
        email: '',
      })
    },

    removeEmail(index) {
      this.referees.splice(index, 1)
    },

    resetBanner() {
      this.displayBanner = ''
    },

    removeOrAddHide(reference, index, addOrRemove, fieldName) {
      const field = this.$refs[reference][0].$el
      if (addOrRemove === 'remove') field.classList.remove('hide')
      if (addOrRemove === 'add' && !this.referees[index][fieldName]) field.classList.add('hide')
    },
  },

  validations: {
    referees: {
      $each: {
        firstName: {
          required,
        },
        lastName: {
          required,
        },
        email: {
          emailValidation,
          required,
        },
      },
    },
  },
}
</script>
