<template>
  <b-modal class="modal-info" ok-only :title="title" :id="id">
    <div class="row mr-lg-4">
      <div class="col-12 col-md-3 text-center">
        <img :src="require(`@/assets/icons/${img}.svg`)" class="pb-5" />
      </div>
      <p class="col" v-html="text"></p>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'PopupInfo',
  props: ['title', 'text', 'id', 'img'],
}
</script>
