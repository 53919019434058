<template>
  <div v-if="content" class="layout">
    <Header></Header>
    <router-view></router-view>
    <Footer></Footer>
    <popups></popups>
  </div>
  <div v-else>
    <loader :fullscreen="true"></loader>
  </div>
</template>

<script>
import Header from '@/app/components/main/header/Header'
import Footer from '@/app/components/main/footer/Footer'
import Popups from '@/app/components/main/popup/Popups'
import Vue from 'vue'
import VueCalendly from 'vue-calendly'
import debounce from 'lodash.debounce'

Vue.use(VueCalendly)

export default {
  name: 'new',
  mixins: [],
  components: {
    Header,
    Footer,
    Popups,
  },
  computed: {
    locale() {
      return this.$store && this.$store.getters.locale
    },
    selectedLocale() {
      return this.$store.getters.locale
    },
  },
  watch: {
    selectedLocale() {
      this.$store.dispatch('getContentDefault')
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
      document.addEventListener('click', this.handleScrollTo)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
    document.removeEventListener('click', this.handleScrollTo)
  },
  methods: {
    onResize: debounce(function () {
      this.$store.commit('SET_WIDTH', window.innerWidth)
    }, 50),
    handleScrollTo(e) {
      if (e.target && e.target.hasAttribute('scrollto')) {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }
    },
  },
}
</script>
