<template>
  <b-container class="p-0">
    <b-row>
      <b-col cols="12">
        <b-alert
          :variant="display"
          :show="true"
          :class="'alert-banner-' + display"
          :dismissible="dismissible"
        >
          <div v-html="$options.filters.replaceDynamicContent(text)"></div>
        </b-alert>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: ['display', 'text', 'dismissible'],
}
</script>
