import Vue from 'vue'
import { router, routerGuards } from '@/app/router'

import ChangePassword from '@/app/components/authorization/ChangePassword'
import Login from '@/app/components/authorization/Login'
import ForgotPassword from '@/app/components/authorization/ForgotPassword'
import PasswordRequestSent from '@/app/components/authorization/PasswordRequestSent'
import ConfirmNewPassword from '@/app/components/authorization/ConfirmNewPassword'

router.addRoute({
  path: Vue.types.pages.LOGIN,
  name: 'Login',
  component: Login,
  beforeEnter: routerGuards.ifNotAuthenticated,
})
router.addRoute({
  path: `${Vue.types.pages.LOGIN}/changePassword`,
  name: 'changePassword',
  component: ChangePassword,
  beforeEnter: routerGuards.ifNotAuthenticated,
})
router.addRoute({
  path: `${Vue.types.pages.LOGIN}/passwordrequestsent`,
  name: 'Passwordrequestsent',
  component: PasswordRequestSent,
  beforeEnter: routerGuards.ifNotAuthenticated,
})
router.addRoute({
  path: `${Vue.types.pages.LOGIN}/forgotpassword`,
  name: 'ForgotPassword',
  component: ForgotPassword,
  beforeEnter: routerGuards.ifNotAuthenticated,
})
router.addRoute({
  path: `${Vue.types.pages.LOGIN}/confirmnewpassword`,
  name: 'ConfirmNewPassword',
  component: ConfirmNewPassword,
  props: true,
  beforeEnter: routerGuards.ifNotAuthenticated,
})
