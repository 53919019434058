<template>
  <div>
    <div v-for="(infoPopup, index) in infoPopups" :key="index">
      <PopupInfo
        :img="infoPopup.img"
        :id="infoPopup.id"
        :title="infoPopup.title"
        :text="infoPopup.text"
      />
    </div>
  </div>
</template>

<script>
import PopupInfo from './PopupInfo'

export default {
  name: 'popups',
  components: {
    PopupInfo,
  },
  methods: {
    bindInfoPoups() {
      document.addEventListener('click', (event) => {
        const target = event.target
        if (target && target.hasAttribute('data-info-popup')) {
          const id = target.getAttribute('data-info-popup')
          if (id) {
            this.$root.$emit('bv::show::modal', id)
          }
        }
      })
    },
  },
  computed: {
    infoPopups() {
      return [
        {
          id: 'wattpiek',
          img: 'wattpiek',
          title: this.content.pv_slider_wattpiek_title,
          text: this.content.pv_slider_wattpiek_text,
        },
        {
          id: 'production',
          img: 'production',
          title: this.content.pv_slider_production_title,
          text: this.content.pv_slider_production_text,
        },
        {
          id: 'paybacktime',
          img: 'panels-color',
          title: this.content.pv_slider_paybacktime_title,
          text: this.content.pv_slider_paybacktime_text,
        },
        {
          id: 'arei',
          img: 'arei',
          title: this.content.pv_slider_arei_title,
          text: this.content.pv_slider_arei_text,
        },
        {
          id: 'assembly',
          img: 'assembly',
          title: this.content.pv_slider_assembly_title,
          text: this.content.pv_slider_assembly_text,
        },
        {
          id: 'environment',
          img: 'panels-color',
          title: this.content.pv_slider_environmentalprofit_title,
          text: this.content.pv_slider_environmentalprofit_text,
        },
        {
          id: 'installation',
          img: 'installation',
          title: this.content.pv_slider_installation_title,
          text: this.content.pv_slider_installation_text,
        },
        {
          id: 'energyProductionKey',
          img: 'production',
          title: this.content.monitoring_energy_production_key_popup_title,
          text: this.content.monitoring_energy_production_key_popup_text,
        },
        {
          id: 'sunsureProgressInfoPopup',
          img: 'production',
          title: this.content.b2b_sunsure_progress_info_popup_title,
          text: this.content.b2b_sunsure_progress_info_popup_text,
        },
        {
          id: 'co2CalculationPopup',
          img: 'house-illustration',
          title: this.content.environmental_gains_co2_popup_title,
          text: this.content.environmental_gains_co2_popup_text,
        },
        {
          id: 'kmsCalculationPopup',
          img: 'monitoring-car',
          title: this.content.environmental_gains_kms_popup_title,
          text: this.content.environmental_gains_kms_popup_text,
        },
        {
          id: 'treesCalculationPopup',
          img: 'trees-illustration',
          title: this.content.environmental_gains_trees_popup_title,
          text: this.content.environmental_gains_trees_popup_text,
        },
      ]
    },
  },
  created() {
    this.bindInfoPoups()
  },
  mounted() {
    this.bindInfoPoups()
  },
}
</script>
