<template>
  <div class="col col-12 col-md-6">
    <h3 class="text-primary" v-dynamic-content:faq_title></h3>
    <section class="mt-6">
      <div v-if="flatFaqs">
        <div v-for="(faq, i) of flatFaqs" :key="faq.id" class="question mb-3">
          <div
            class="collapse-item d-flex justify-items-between align-items-center"
            v-b-toggle="`${page}-${i}`"
          >
            <h4
              class="pl-3 mb-0 mt-1"
              v-html="$options.filters.replaceDynamicContent(faq.question)"
            ></h4>
          </div>
          <b-collapse :id="`${page}-${i}`" class="pl-6 mt-2" accordion="faq">
            <p v-html="$options.filters.replaceDynamicContent(faq.answer)"></p>
          </b-collapse>
        </div>
        <p v-if="pagelink" class="d-flex flex-wrap my-8">
          <span class="font-weight-bold pr-2" v-dynamic-content:monitoring_faq_subtext></span>
          <a
            :href="`https://sungevity.freshdesk.com/${this.profile.locale.substring(
              0,
              2
            )}/support/home`"
            class="d-flex justify-content-center align-items-center font-weight-bold"
            target="_blank"
          >
            <span>{{ pagelink.text }}<img class="ml-2" src="@/assets/icons/forward.svg" /></span>
          </a>
        </p>
      </div>
      <div v-else>
        <loader></loader>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: ['pagelink', 'page'],
  name: 'FAQ',
  computed: {
    content() {
      return this.$store.state.data.content
    },
    flatFaqs() {
      return this.$store.getters.flatFaqs(this.page)
    },
  },
}
</script>
