<template>
  <div class="back-to-top w-100 text-right" @click="scrolltoTop">
    <img ref="backtoTopButton" class="invisible" src="@/assets/icons/back-to-top.svg" />
  </div>
</template>

<script>
export default {
  name: 'BacktoTopButton',
  mounted() {
    window.addEventListener('scroll', () => {
      this.fadeIn()
    })
  },
  updated() {
    window.addEventListener('scroll', () => {
      this.fadeIn()
    })
  },
  methods: {
    checkVisible() {
      if (this.$refs.backtoTopButton) {
        const elm = this.$refs.backtoTopButton
        const rect = elm.getBoundingClientRect()
        const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
        return !(rect.bottom < 0 || rect.top - viewHeight >= 0)
      }
      return false
    },
    fadeIn() {
      const elm = this.$refs.backtoTopButton
      if (this.checkVisible() === true) {
        elm.classList.remove('invisible')
        elm.classList.add('fade-in')
      }
    },
    scrolltoTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    },
  },
}
</script>
