<template>
  <b-container class="mb-2">
    <b-row>
      <b-col order-md="1" order-sm="2" class="mr-auto p-0" cols="12" md="6" lg="4">
        <div
          class="font-weight-semibold collapse-item justify-items-between d-flex align-items-center"
          v-b-toggle.collapse1
          @click="getEmailTemplate"
        >
          <h4 v-dynamic-content:referrals_see_email_button class="pl-3 pt-1"></h4>
        </div>
      </b-col>
      <b-col order-md="2" order-sm="1" cols="12" md="6" lg="4" class="mb-sm-7 p-0">
        <loader v-if="pending"></loader>
        <b-button
          v-else
          type="submit"
          variant="primary"
          :disabled="pending"
          class="px-9 w-100"
          v-dynamic-content:referrals_send_email_button
          @click="send"
        ></b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  props: ['pending'],
  methods: {
    send() {
      this.$emit('send')
    },
    getEmailTemplate() {
      if (!this.$store.state.referrals.emailTemplate) {
        this.$store.dispatch('setEmailTemplate')
      }
    },
  },
}
</script>
