import Vue from 'vue'
import moment from 'moment'
import store from '@/app/store'

// format date with current locale
Vue.filter('formatDate', (value) => {
  // set the locale globally.
  moment.locale(store.getters.locale)
  if (value) {
    return moment(value).format('LL') // moment().format('LL') format with locale support. LL stands for the format that we use, without time
  }
  return value
})

Vue.filter('languageText', (value) => {
  if (value) {
    const languageKey = value.split('_')[0]
    return store.state.data.content[`language_selection_${languageKey}`]
  }
  return value
})
