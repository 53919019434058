import Vue from 'vue'
import format from 'string-format'

// replace our tags with something with <>
const replaceTags = (value) => {
  if (!value) return value
  let text = value
  const replacers = [
    {
      s: 'b',
      replace: 'strong',
    },
    {
      s: 'sup',
      replace: 'sup',
    },
    {
      s: 'sub',
      replace: 'sub',
    },
  ]

  replacers.forEach((rep) => {
    const regex1 = new RegExp(`\\[${rep.s}\\]`, 'g') // open tag
    const regex2 = new RegExp(`\\[/${rep.s}\\]`, 'g') // close tag
    text = text.replace(regex1, `<${rep.replace}>`).replace(regex2, `</${rep.replace}>`)
  })

  return text
}

// replace non breacking spaces [s]
const nonBreakinSpace = (value) => {
  if (!value) return value
  const regexSpace = new RegExp('\\[s\\]', 'g')
  return value.replace(regexSpace, '&nbsp;')
}

// break
const breaktag = (value) => {
  const regexBreak = /\[br\]/g
  return value.replace(regexBreak, '</br>')
}

// replace [info]id[/info] with clickable infoicons
const infoBlock = (value) => {
  if (!value) return value
  const regexInfo = new RegExp('\\[info\\](.*?)\\[/info\\]', 'g') // information popup is [info]id[/info]
  const resultInfo = regexInfo.exec(value)
  if (resultInfo) {
    return value.replace(
      regexInfo,
      `<a class="info-symbol" role="button" data-info-popup="${resultInfo[1]}"></a>`
    )
  }
  return value
}

const anchor = (value) => {
  // target blank [a|link|blank]label [/a]
  const regexMark = /\[a(.*?)\](.*?)\[\/a\]/g
  let text = value
  let resultMark
  while ((resultMark = regexMark.exec(text)) !== null) {
    let target = ''
    const options = resultMark[1] ? resultMark[1].split('|') : [] // resultMark[1] is where the target has to go
    if (options.length > 0) {
      if (options.length > 2) {
        if (options[2] === 'blank') {
          target = ' target="_blank"'
        }
      }
      text = text.replace(resultMark[0], `<a${target} href="${options[1]}">${resultMark[2]}</a>`)
    }
  }
  return text
}

const scrolltop = (value) => {
  const regexScroll = new RegExp('\\[scrolltop\\](.*?)\\[/scrolltop\\]', 'g')
  const resultScroll = regexScroll.exec(value)
  if (resultScroll) {
    const replaced = value.replace(
      resultScroll[0],
      `<a class="link" role="button" scrollto="top">${resultScroll[1]}</a>`
    )
    return replaced
  }
  return value
}

// replace {i} by dynamic content
const placeholders = (value, args) => (args ? format(value, ...args) : value)

export const replaceDynamicContent = (value, args) => {
  if (value === '') {
    return null
  }
  if (value) {
    let text = value

    // apply all placeholders
    text = replaceTags(text)
    text = nonBreakinSpace(text)
    text = infoBlock(text)
    text = anchor(text)
    text = scrolltop(text)
    text = breaktag(text)

    // dynamic content
    text = placeholders(text, args)
    return text
  }
  return null
}

Vue.filter('replaceDynamicContent', replaceDynamicContent)
