<template>
  <form-template>
    <template slot="side">
      <inlog-locale-switch></inlog-locale-switch>
      <h2 class="text-primary" v-dynamic-content:login_title_forgetpassword></h2>
      <p v-dynamic-content:login_text_cta_forgetpassword></p>
      <p v-dynamic-content:login_text_forgetpassword></p>
    </template>

    <template slot="form">
      <b-alert variant="danger" :show="!!errorMsg">{{ errorMsg }}</b-alert>
      <form @submit.prevent="forgotpassword" novalidate>
        <div>
          <label class="mb-0" v-dynamic-content:login_field_email_text></label>
          <b-form-input
            type="email"
            v-model="email"
            autocomplete="email"
            :placeholder="content.login_field_email"
            :state="$v.email.$dirty ? !$v.email.$invalid : null"
            :disabled="loading"
          ></b-form-input>
          <b-form-invalid-feedback>
            <div v-if="!$v.email.emailValidation">{{ content.login_error_invalid_email }}</div>
            <div v-if="!$v.email.requiredValidation">{{ content.login_error_required }}</div>
          </b-form-invalid-feedback>
        </div>
        <b-button
          class="btn-block mt-5"
          type="submit"
          variant="primary"
          v-dynamic-content:login_button_create_password
        ></b-button>
        <router-link to="/login">
          <p class="text-center mt-4" v-dynamic-content:login_backtologin></p>
        </router-link>
      </form>
    </template>
  </form-template>
</template>

<script>
import Vue from 'vue'

import FormMixin from '@/app/components/main/form/FormMixin'
import InlogLocaleSwitch from './parts/InlogLocaleSwitch'
import { Auth } from 'aws-amplify'

export default {
  name: 'ForgotPassword',
  mixins: [FormMixin],
  components: {
    InlogLocaleSwitch,
  },
  data() {
    return {
      email: null,
    }
  },
  methods: {
    async forgotpassword() {
      const email = this.email.toLowerCase()

      this.$v.$touch()
      if (this.$v.email.$invalid) {
        return
      }

      try {
        await Auth.forgotPassword(email)
        this.$router.push(`${Vue.types.pages.LOGIN}/passwordrequestsent`)
      } catch (error) {
        this.formErrorHandling(error)
      }
    },
  },
  beforeUpdate() {
    this.$store.commit('SET_HEADER_TEXT', null)
  },
}
</script>
