<template>
  <b-container class="p-0 mb-md-6">
    <b-row>
      <b-col
        order="2"
        order-md="1"
        sm="12"
        md="6"
        lg="7"
        class="mr-lg-auto mt-6 mt-md-0 text-center text-md-left p-0 px-md-4"
      >
        <section class="monitoring-production-timebuttons d-inline-block">
          <b-form-radio-group
            id="timeButtonsGraph"
            class="timebuttons"
            buttons
            button-variant="primary"
            v-model="selectedInterval"
            :options="intervalOptions"
            name="timeButtonsGraph"
          />
        </section>
      </b-col>
      <b-col order-md="2" order="1" sm="12" md="6" lg="5">
        <div>
          <form class="form" novalidate>
            <div class="d-flex justify-content-center float-md-right">
              <div v-if="loading" class="mr-2">
                <b-spinner
                  style="width: 2rem; height: 2rem"
                  class="flex spinner-border-sm mr-2 sungevity-orange"
                ></b-spinner>
              </div>
              <div>
                <date-picker v-model="selectedDate" v-bind="datePickerSettings"></date-picker>
              </div>
            </div>
          </form>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { DateTime } from 'luxon'
import nl from 'vue2-datepicker/locale/nl'
import fr from 'vue2-datepicker/locale/fr'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const format = {
  day: 'DD-MM-YYYY',
  week: '[week] w - YYYY',
  month: 'MMMM YYYY',
  year: 'YYYY',
}

const i18n = {
  fr: {
    lang: fr,
    format,
  },
  nl: {
    lang: nl,
    format,
  },
}

export default {
  name: 'SelectInterval',
  components: { DatePicker },
  computed: {
    ...mapState({
      loading: ({ monitoring }) => monitoring.loading.getDeltas,
      showHourlyData({ monitoring }) {
        const brands =
          monitoring.location.meteringSystems &&
          monitoring.location.meteringSystems.map((ms) => ms.brand)
        return brands && brands.every((b) => b === 'Simpl' || b === 'Solis')
      },
    }),
    ...mapGetters(['language']),
    selectedDate: {
      get() {
        return DateTime.fromISO(this.$store.state.monitoring.inputs.intervalStartAt).toJSDate()
      },
      set(v) {
        const value = DateTime.fromJSDate(v).toISODate()
        value && this.$store.commit('SET_INPUT_FIELD', { key: 'intervalStartAt', value })
      },
    },
    selectedInterval: {
      get() {
        return this.$store.state.monitoring.inputs.interval
      },
      set(value) {
        const lastReadingAtRaw = this.$store.state.monitoring.location.lastReadingAt
        const lastReadingAt = DateTime.fromISO(lastReadingAtRaw) || DateTime.now()

        this.$store.commit('SET_INPUT_FIELDS', {
          interval: value,
          intervalStartAt: lastReadingAt.minus({ day: 1 }).startOf(value).toISODate(),
          page: 0,
        })
      },
    },
    intervalOptions() {
      return [
        {
          text: this.content.monitoring_production_graph_time_day,
          value: 'day',
          hide: !this.showHourlyData,
        },
        {
          text: this.content.monitoring_production_graph_time_week,
          value: 'week',
        },
        {
          text: this.content.monitoring_production_graph_time_month,
          value: 'month',
        },
        {
          text: this.content.monitoring_production_graph_time_year,
          value: 'year',
        },
      ].filter((e) => !e.hide)
    },
    format() {
      return i18n[this.language].format[this.selectedInterval]
    },
    langSettings() {
      return i18n[this.language].lang
    },
    datePickerSettings() {
      return {
        clearable: false,
        lang: this.langSettings,
        format: this.format,
        type: this.selectedInterval,
        'disabled-date': (d) => {
          const date = DateTime.fromJSDate(d)
          const firstReadingAt = DateTime.fromISO(this.$store.getters.firstReadingAt).startOf(
            this.selectedInterval
          )
          const now = DateTime.now()
          return firstReadingAt > date || date > now
        },
      }
    },
  },
}
</script>

<style lang="scss">
.sungevity-orange {
  color: $brand-color;
}
</style>
