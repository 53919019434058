<template>
  <form-template>
    <template slot="form">
      <h2 class="mb-6">Please enter the user's email</h2>
      <b-container class="p-0">
        <b-row class="mb-6">
          <b-col>
            <b-form-input
              v-model="userEmail"
              :placeholder="'email'"
              :disabled="loading"
            ></b-form-input>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-button class="btn-block" @click="fetchUser()" variant="primary">Search</b-button>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div v-if="userList.length">
              <div
                v-for="(user, index) in userList"
                :key="index"
                class="container card-full-width-mobile p-0"
              >
                <b-card tag="article" class="mt-4 mb-2">
                  <p>
                    Name: {{ findImportantAttributes('given_name', user) }}
                    {{ findImportantAttributes('family_name', user) }}
                  </p>
                  <p>Email: {{ findImportantAttributes('email', user) }}</p>
                  <p>Account Number: {{ findImportantAttributes('custom:accountNumber', user) }}</p>
                  <b-button class="btn-block" @click="impersonateUser(user)" variant="primary"
                    >View MySungevity</b-button
                  >
                </b-card>
              </div>
            </div>
            <div v-if="error" class="mt-4">
              <p>{{ error }}</p>
            </div>
          </b-col>
          <b-col sm="12" class="mt-10">
            <a :href="$store.state.data.adminPortalLink">Return to Admin Portal</a>
          </b-col>
          <b-col sm="12" class="mt-4">
            <b-button class="btn-block" variant="primary" @click="adminSignOut()">Logout</b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </form-template>
</template>

<script>
import Vue from 'vue'
import config from 'config'

export default {
  name: 'Impersonate',
  data() {
    return {
      error: '',
      userEmail: '',
      loading: false,
    }
  },
  computed: {
    userList() {
      return this.$store.state.admin.userList
    },
  },
  methods: {
    async fetchUser() {
      this.error = ''
      const params = {
        UserPoolId: config['/global/cognito.user.pool.id'],
        Limit: 10,
        Filter: `email^="${this.userEmail}"`,
      }

      await this.$store.dispatch('listUsers', params)
      if (!this.userList.length) this.error = 'Could not find any users'
    },
    findImportantAttributes(attToFind, user) {
      const attribute = user.Attributes.find((att) => att.Name === attToFind)
      return attribute && attribute.Value
    },
    impersonateUser(user) {
      this.$store.dispatch('setImpersonatedAccount', user)
      this.$router.push('/')
    },
    async adminSignOut() {
      await this.$store.dispatch('adminSignOut')
      this.$router.push(Vue.types.pages.ADMIN)
    },
  },
}
</script>
