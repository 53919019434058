<template>
  <footer class="footer bg-light">
    <div class="container py-7">
      <div class="row">
        <div class="col-12 col-md col-lg-6">
          <div class="row justify-content-center justify-content-md-start">
            <img
              class="footer-logo"
              :src="require(`../../../../assets/icons/mysungevity/logo-sungevity-engie-color.svg`)"
            />
          </div>
        </div>
        <div class="col-12 col-md mt-2">
          <div class="pb-5 text-center text-md-left">
            <a class="social-inner" :href="`mailto:${content.footer_email_link}`">
              <span v-dynamic-content:footer_email></span>
            </a>
          </div>
          <div class="pb-5 text-center text-md-left">
            <span
              class="social-inner"
              v-html="$options.filters.replaceDynamicContent(content['footer_website'])"
            ></span>
          </div>
          <div class="pb-5 text-center text-md-left">
            <a class="social-inner" :href="`tel:${content.footer_phone_link}`">
              <span v-dynamic-content:footer_phone></span>
            </a>
          </div>
        </div>
        <div class="col-12 col-md">
          <div class="d-flex mb-5 justify-content-center justify-content-md-start">
            <div class="mr-1">
              <a :href="content.footer_social_facebook_link" target="_blank">
                <img src="@/assets/icons/social/Facebook.svg" />
              </a>
            </div>
            <div class="mr-1">
              <a :href="content.footer_social_linkedin_link" target="_blank">
                <img src="@/assets/icons/social/Linkedin.svg" />
              </a>
            </div>
          </div>
          <div v-if="content.footer_privacy" class="pb-5 text-center text-md-left">
            <a class="social-inner" :href="content.footer_privacy_link" target="_blank">
              <span v-dynamic-content:footer_privacy></span>
            </a>
          </div>
          <div v-if="content.footer_conditions" class="pb-5 text-center text-md-left">
            <a class="social-inner" :href="content.footer_conditions_link" target="_blank">
              <span v-dynamic-content:footer_conditions></span>
            </a>
          </div>
          <div class="pb-5 pt-7 text-center text-md-left">
            <span class="social-inner" v-dynamic-content:footer_copyright></span>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>
