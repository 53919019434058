<template>
  <div v-if="error">{{ error }}</div>
  <div v-else-if="!isAdmin">
    <admin-login></admin-login>
  </div>
  <div v-else>
    <impersonate></impersonate>
  </div>
</template>

<script>
import { Hub } from 'aws-amplify'
import AdminLogin from './AdminLogin'
import Impersonate from './Impersonate'
import { mapGetters } from 'vuex'

export default {
  name: 'Admin',
  components: {
    AdminLogin,
    Impersonate,
  },
  data() {
    return {
      error: '',
    }
  },
  computed: {
    ...mapGetters(['isAdmin']),
  },
  beforeCreate() {
    Hub.listen('auth', async (data) => {
      switch (data.payload.event) {
        case 'signIn': {
          const adminUser = data.payload.data.signInUserSession.idToken.payload
          this.$store.dispatch('setAdminUser', adminUser)
          break
        }
        case 'signIn_failure': {
          this.error = 'There was a problem signing you in'
          break
        }
        default:
          break
      }
    })
  },
}
</script>
