<template>
  <div>
    <b-button
      class="bg-transparent border-0 mb-md-4 p-0 d-none d-sm-flex d-md-none ml-auto"
      size="sm"
      @click="add"
      v-if="index == false && showSmall"
    >
      <img src="@/assets/icons/plus-icon.svg" />
    </b-button>
    <b-button
      class="bg-transparent border-0 mb-md-4 p-0 d-none d-sm-flex d-md-none ml-auto"
      size="sm"
      @click="remove(index)"
      v-else-if="showSmall"
    >
      <img src="@/assets/icons/minus-icon.svg" />
    </b-button>
    <b-button
      class="bg-transparent border-0 referral-add-subtract d-none d-lg-flex"
      size="sm"
      @click="add"
      v-if="index == false"
    >
      <img src="@/assets/icons/plus-icon.svg" />
    </b-button>
    <b-button
      class="bg-transparent border-0 referral-add-subtract d-none d-lg-flex"
      size="sm"
      @click="remove(index)"
      v-else
    >
      <img src="@/assets/icons/minus-icon.svg" />
    </b-button>
    <b-button
      class="bg-transparent border-0 referral-add-subtract-medium d-none d-md-flex d-lg-none"
      size="sm"
      @click="add"
      v-if="index == false"
    >
      <img src="@/assets/icons/plus-icon.svg" />
    </b-button>
    <b-button
      class="bg-transparent border-0 referral-add-subtract-medium d-none d-md-flex d-lg-none"
      size="sm"
      @click="remove(index)"
      v-else
    >
      <img src="@/assets/icons/minus-icon.svg" />
    </b-button>
  </div>
</template>

<script>
export default {
  props: ['index', 'showSmall'],
  methods: {
    add() {
      this.$emit('add')
    },
    remove() {
      this.$emit('remove', this.index)
    },
  },
}
</script>
